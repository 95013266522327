import { useParams } from 'react-router-dom';

const useGetParams = () => {
  const { event_id: eventId, business_id: businessId } = useParams();
  if (!eventId || !+eventId || !businessId || !+businessId) {
    throw new Error('no event_id or business_id');
  }

  return { businessId: +businessId, eventId: +eventId };
};

export default useGetParams;
