import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControlField from './FormControlField';
import UserDTO from '../models/user';
import Select from './Select';
import Button from './Button';
import {
  useAddUserToEventMutation,
} from '../store';
import { toastSucces } from '../utils/toasts';

interface Props {
  businessId: number;
  eventId: number;
  users: UserDTO[];
  onSuccess: () => void;
}

interface FormData {
  user_id: number;
  comission_rate: number;
}

const validationSchema = yup.object<FormData>().shape({
  user_id: yup.number().required('Debes seleccionar un usuario'),
  comission_rate: yup
    .number()
    .required('Debes seleccionar una opción')
    .min(0, 'La comisión debe ser mayor a 0')
    .max(100, 'La comisión debe ser menor a 100'),
});

const AddSellerToEvent: React.FC<Props> = ({
  users, businessId, eventId, onSuccess,
}) => {
  const form = useForm<FormData>({
    resolver: yupResolver<FormData>(validationSchema),
  });

  const { control, handleSubmit, formState } = form;

  const [addUserToEvent, addUserToEventResults] = useAddUserToEventMutation();

  const handleAddUser = async () => {
    try {
      const values = form.getValues();
      const result = await addUserToEvent({
        businessId,
        eventId,
        userData: {
          userId: values.user_id,
          comissionRate: values.comission_rate / 100,
        },
      });
      if ('data' in result) {
        toastSucces('Vendedor agregado al evento');
        onSuccess();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <form className="w-full h-full" onSubmit={handleSubmit(handleAddUser)}>
        <div className="mb-5">
          <Controller
            name="user_id"
            control={control}
            render={({ field }) => (
              <Select
                label="Usuario"
                id="user_id"
                placeholder="Selecciona un usuario"
                options={users.map((user) => ({
                  label: user.username,
                  value: user.id,
                }))}
                {...field}
                errMsg={formState.errors.user_id?.message}
              />
            )}
          />
        </div>
        <div className="mb-5">
          <Controller
            name="comission_rate"
            control={control}
            render={({ field }) => (
              <FormControlField
                label="Comisión"
                labelAbove={true}
                id="comission_rate"
                {...field}
                onChange={(e) => field.onChange(e.target.value.replace(/\s/g, ''))
                }
                errMsg={formState.errors.comission_rate?.message}
              />
            )}
          />
        </div>
        <div className="flex justify-center align-items-center">
          <Button
            className="w-full"
            primary
            loading={addUserToEventResults.isLoading}
          >
            Enviar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddSellerToEvent;
