import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setOrderItemData } from '../store';
import FormControlField from '../components/FormControlField';
import Button from '../components/Button';
import { OrderItemData } from '../models/order';
import TicketDTO from '../models/ticket';
import Checkbox from '../components/Checkbox';
import FormCardFrame from '../components/FormCardFrame';
import SectionTitle from '../components/SectionTitle';
import TextTitleCard from '../components/TitleTextCard';
import findDuplicates from '../utils/findDuplicates';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

const ticketSchema = yup.object<OrderItemData>().shape({
  name: yup.string().required('Debes ingresar un nombre'),
  last_name: yup.string().required('Debes ingresar un apellido'),
  person_id: yup.string().required('Debes ingresar un DNI'),
});

const schema = yup.object().shape({
  tickets: yup.array().of(ticketSchema),
});

const CompleteEntryFieldsPage: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const dispatch = useDispatch();

  const selectedTickets = useSelector(
    (state: RootState) => state.tickets.ticketsSelected,
  );

  const event = useSelector((state: RootState) => state.events.selectedEvent);

  const [customizeData, setCustomizeData] = useState(
    event?.require_id_tickets || false,
  );

  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    if (!event) {
      navigate('../../');
    }
  }, [event]);

  if (!event) {
    return null;
  }
  const ticketItems: TicketDTO[] = selectedTickets
    .map((item) => Array(item.quantity * item.ticket.items_create).fill(item.ticket))
    .flat();

  const formDefaultValues = (): {
    tickets:
    | { name: string; last_name: string; person_id: string }[]
    | undefined;
  } => {
    const savedData = localStorage.getItem('formItemsData');
    if (savedData) {
      return JSON.parse(savedData);
    }
    return {
      tickets: ticketItems.map(() => ({
        name: '',
        last_name: '',
        person_id: '',
      })),
    };
  };

  const {
    register, handleSubmit, formState, getValues, setValue, trigger,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues(),
  });

  const onSubmit = () => {
    const data = getValues('tickets') || [];
    const orderItemData = data.map((d, index) => ({
      ...d,
      ticket: ticketItems[index],
      ticket_id: ticketItems[index].id,
    }));
    dispatch(setOrderItemData(orderItemData));
    localStorage.setItem('formItemsData', JSON.stringify(getValues()));
    const nextPath = event.has_polls
      ? '../poll'
      : `../checkout?customized=${customizeData}`;
    navigate(nextPath);
  };

  const onGoBack = () => {
    localStorage.removeItem('formItemsData');
    navigate('../tickets');
  };

  const handleStateAndSubmit = async () => {
    const data = getValues('tickets') || [];
    if (!customizeData && data.length > 0) {
      const firstTicket = data[0];
      ticketItems.forEach((_, index) => {
        setValue(`tickets.${index}.name`, firstTicket?.name || '');
        setValue(`tickets.${index}.last_name`, firstTicket?.last_name || '');
        setValue(`tickets.${index}.person_id`, firstTicket?.person_id || '');
      });
    }
    const duplicates = findDuplicates(data.map((d) => d.person_id));
    if (duplicates.length > 0 && event.require_id_tickets) {
      return setValidationError('Los DNI no pueden repetirse');
    }
    setValidationError('');

    const result = await trigger();
    if (result) {
      return handleSubmit(onSubmit)();
    }
    return '';
  };

  return (
    <div>
      {event.require_id_tickets && (
        <div className='mb-4'>
          <TextTitleCard>
            <div className="text-center">
              <div className="font-medium">🚨 IMPORTANTE 🚨</div>
              <p>
                Asegurate de cargar bien el nombre, apellido y DNI de cada
                asistente. Las entradas son únicas y no se permiten datos
                duplicados.
              </p>
              <p>¡No te equivoques!</p>
              <p>
                Son <span className="font-medium">ÚNICAS</span> e{' '}
                <span className="font-medium">INTRANSFERIBLES</span>.
              </p>
            </div>
          </TextTitleCard>
        </div>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleStateAndSubmit();
        }}
      >
        <FormCardFrame>
          {!event.require_id_tickets && ticketItems.length > 1 && (
            <div className="my-2 pl-1">
              <Checkbox
                label="Personalizar datos de entradas"
                labelClassName="text-gray-900 font-medium text-sm"
                checked={customizeData}
                onChange={() => setCustomizeData(!customizeData)}
              />
            </div>
          )}
          {ticketItems.map((item, index) => (
            <div key={index}>
              {(customizeData || index === 0) && (
                <div>
                  <SectionTitle
                    index={index}
                    customizeData={customizeData}
                    item={item}
                  />
                  <div className="space-y-2">
                    <FormControlField
                      id="name"
                      label="Nombre"
                      {...register(`tickets.${index}.name`)}
                      errMsg={formState.errors.tickets?.[index]?.name?.message}
                    />
                    <FormControlField
                      id="last_name"
                      label="Apellido"
                      {...register(`tickets.${index}.last_name`)}
                      errMsg={
                        formState.errors.tickets?.[index]?.last_name?.message
                      }
                    />
                    <FormControlField
                      id="person_id"
                      label="DNI"
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      {...register(`tickets.${index}.person_id`)}
                      errMsg={
                        formState.errors.tickets?.[index]?.person_id?.message
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        <span className="text-red-500 white-text-shadow">{validationError}</span>
        </FormCardFrame>
        <div className="flex flex-col justify-center mt-10">
          <Button type="submit" className="mb-2 h-12" primary>
            Siguiente
          </Button>
          <Button type="button" transparent onClick={onGoBack}>
            Volver
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CompleteEntryFieldsPage;
