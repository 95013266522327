import React from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { useForm } from 'react-hook-form';
import Button from './Button';
import FormControlField from './FormControlField';

interface ScanResultCardProps {
  onValidate: (personId: string) => void;
}

const SearchByPersonId: React.FC<ScanResultCardProps> = ({ onValidate }) => {
  const form = useForm<{ personId: string }>({
    defaultValues: {
      personId: '',
    },
  });

  const { register, handleSubmit } = form;

  const submit = (data: { personId: string }) => {
    onValidate(data.personId);
  };

  return (
    <div className={'bg-gray-50 h-full p-6 w-full'}>
      <div>
        <div className="flex justify-center mb-4">
          <div className={'bg-gray-200 mt-10 rounded-full p-8'}>
            <IoSearchOutline className="w-20 h-20" />
          </div>
        </div>
        <h2 className={'text-center text-3xl font-medium mb-2'}>
          Validador de DNI
        </h2>
        <div>
          <p className="text-center text-lg text-gray-600 mb-2">
            Ingresá el numero de documento del asistente para poder validar su
            código.
          </p>
          <form onSubmit={handleSubmit(submit)} className="mt-24">
            <FormControlField
              label="Documento de identidad"
              id={'person_id'}
              labelAbove={true}
              type="number"
              inputMode='numeric'
              {...register('personId' as const)}
            />
            <div className="flex flex-col justify-center my-10">
              <Button type="submit" className="mb-2 h-12 text-lg mt-10" primary>
                Validar
              </Button>
              <Button type="button" transparent onClick={() => onValidate('')}>
                Cancelar
              </Button>
            </div>
          </form>
          <div />
        </div>
      </div>
    </div>
  );
};

export default SearchByPersonId;
