import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RootState, useGetEmailInvitationsQuery } from '../store';
import LoadingLottie from '../components/LoadingLottie';
import JoinBusinessCard from '../components/JoinBusinessCard';
import InvitationDTO from '../models/invitation';

const InvitationsPage: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  if (!user) {
    throw new Error('no user');
  }
  const { data: invitations, isLoading } = useGetEmailInvitationsQuery({
    email: user.email,
  });

  const [pendingInvitations, setPendingInvitations] = useState<InvitationDTO[]>([]);

  useEffect(() => {
    if (invitations) {
      setPendingInvitations(invitations.filter((inv) => inv.status === 'pending'));
    }
  }, [invitations]);

  return (
    <div className='px-3'>
      <h1 className='mb-10'>Invitaciones pendientes</h1>
      <div className="flex justify-center align-items-center">
        {isLoading && <LoadingLottie />}
        {!isLoading && pendingInvitations && pendingInvitations.length === 0 && (
          <div>No hay invitaciones pendientes</div>
        )}
        {pendingInvitations?.map((inv) => (
          <JoinBusinessCard key={inv.id} user={user} invitation={inv} />
        ))}
      </div>
    </div>
  );
};

export default InvitationsPage;
