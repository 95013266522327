import 'react-loading-skeleton/dist/skeleton.css';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const EventCardSkeleton: React.FC = () => (
  <SkeletonTheme baseColor="#E5E7EB"> {/* gray-500 */}
    <div className="card flex flex-grow p-4 bg-white mb-4 w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
      <div className="h-36 w-36 flex-shrink-0"> {/* Make sure this doesn't shrink */}
        <Skeleton height="100%" width="100%" /> {/* 100% of parent */}
      </div>
      <div className="ml-3 flex-grow"> {/* Allow this to grow */}
        <Skeleton height={20} width="80%" className="mb-1" /> {/* 80% of parent */}
        <div className="text-xs">
        <div className="flex items-center mb-2">
            <Skeleton height={16} width={16} circle={true} className="mr-1" />
            <Skeleton height={12} width={80} />
          </div>
          <div className="flex items-center mb-2">
            <Skeleton height={16} width={16} circle={true} className="mr-1" />
            <Skeleton height={12} width={80} />
          </div>
          <div className="flex items-center mb-2">
            <Skeleton height={16} width={16} circle={true} className="mr-1" />
            <Skeleton height={12} width={80} />
          </div>
        </div>
        <Skeleton height={36} width="80%" className="mt-3" /> {/* 80% of parent */}
      </div>
    </div>
  </SkeletonTheme>
);

export default EventCardSkeleton;
