import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControlField from './FormControlField';
import Checkbox from './Checkbox';
import Button from './Button';
import { useCreateReferralMutation, useUpdateReferralMutation } from '../store';
import { ReferralDTO, CreateReferralDTO } from '../types/Referral';
import useGetParams from '../hooks/useGetParams';
import { generateDisplayId } from '../utils/generateCustomId';

interface Props {
  referral?: ReferralDTO;
  onSuccess: () => void;
}

const validationSchema = yup.object<CreateReferralDTO>().shape({
  active: yup.boolean().required(),
  code: yup.string().required('Debes ingresar un código'),
  name: yup.string().required('Debes ingresar un nombre'),
  description: yup.string().required('Debes ingresar una descripcion'),
});

const CreateEditReferral: React.FC<Props> = ({ referral, onSuccess }) => {
  const { register, handleSubmit, formState } = useForm<CreateReferralDTO>({
    defaultValues: {
      active: referral ? referral.active : true,
      code: referral?.code ?? generateDisplayId(),
      name: referral?.name,
      description: referral?.description,
    },
    resolver: yupResolver<CreateReferralDTO>(validationSchema),
  });

  const { eventId, businessId } = useGetParams();

  const [createReferral, resCreateReferral] = useCreateReferralMutation();
  const [updateReferral, resUpdateReferral] = useUpdateReferralMutation();

  useEffect(() => {
    if (resCreateReferral.isSuccess || resUpdateReferral.isSuccess) {
      onSuccess();
    }
  }, [resCreateReferral.isSuccess, resUpdateReferral.isSuccess, onSuccess]);

  const onSubmit = async (data: CreateReferralDTO) => {
    if (referral) {
      updateReferral({
        referral: { ...data, id: referral.id },
        eventId: +eventId,
        businessId,
      });
    } else {
      createReferral({
        referral: data,
        eventId: +eventId,
        businessId,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
        <div className="mb-4">
          <Checkbox
            label="Habilitado"
            labelClassName="text-gray-900"
            {...register('active')}
          ></Checkbox>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <FormControlField
            {...register('code', { required: 'This field is required' })}
            id="code"
            label="Código"
            labelAbove={true}
            errMsg={formState.errors.code?.message}
          />

          <FormControlField
            {...register('name', { required: 'This field is required' })}
            id="name"
            label="Nombre"
            labelAbove={true}
            errMsg={formState.errors.code?.message}
          />
        </div>

        <FormControlField
          {...register('description', { required: 'This field is required' })}
          id="description"
          label="Descripción"
          labelAbove={true}
          errMsg={formState.errors.code?.message}
        />
        <div className="flex flex-col justify-between mt-5">
          <Button
            type="submit"
            primary
            className="mb-2"
            disabled={!formState.isValid}
            loading={
              resCreateReferral?.isLoading || resUpdateReferral.isLoading
            }
          >
            Guardar cambios
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateEditReferral;
