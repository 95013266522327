/* eslint-disable no-nested-ternary */
import { AiOutlinePercentage } from 'react-icons/ai';
import { Fragment, useState } from 'react';
import OrdersTableSkeleton from './OrdersTableSkeleton';
import { useGetAllCouponsQuery } from '../store';
import Button from './Button';
import IconInfoCard from './IconInfoCard';
import CustomModal from './CustomModal';
import CouponsTable from './CouponsTable';
import CreateEditCoupon from './CreateEditCoupon';
import useGetParams from '../hooks/useGetParams';

const CRUDCoupon: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const { eventId, businessId } = useGetParams();

  const { data: coupons, isFetching } = useGetAllCouponsQuery({
    businessId,
    eventId: +eventId,
  });

  const handleSucessAddCoupon = () => {
    setShowModal(false);
  };

  const handleAddCoupon = () => {
    setShowModal(true);
  };

  return (
    <div className="px-3 h-screen">
      <h1 className="text-xl font-medium mb-4">Configuración de cupones</h1>

      <div className="card border border-gray-200">
        <div className="flex py-2 justify-between">
          <h1 className="text-lg">Cupones</h1>
          <Button onClick={handleAddCoupon} secondary>
            {' '}
            +{' '}
          </Button>
        </div>
        {isFetching && <OrdersTableSkeleton />}{' '}
        {coupons && coupons.length > 0 && (
          <CouponsTable coupons={coupons} eventId={+eventId} businessId={+businessId} />
        )}
        {coupons && coupons.length === 0 && (
          <IconInfoCard
            title="Aún no hay cupones creados"
            info="Aquí aparecerán todos los cupones que crees en el sistema"
            icon={AiOutlinePercentage}
            iconColor="text-indigo-600"
          />
        )}
      </div>
      <CustomModal
        onRequestClose={() => setShowModal(false)}
        isOpen={showModal}
        actionBar={<div></div>}
      >
        <Fragment>
          <div className="flex flex-col justify-center items-center mb-5">
            <h1 className="text-xl font-medium mb-4">Crear cupón</h1>
            <p className="text-gray-400 text-sm text-center">
              Podrás realizar las ediciones que necesites en este cupón
            </p>
          </div>
          <CreateEditCoupon onSuccess={handleSucessAddCoupon} />
        </Fragment>
      </CustomModal>
    </div>
  );
};

export default CRUDCoupon;
