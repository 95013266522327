import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { BusinessDTO } from '../../models/business';

interface UsersState {
  authToken: string | null;
  userBusinesses: BusinessDTO[];
  selectedBusiness: BusinessDTO | null;
}

const initialState: UsersState = {
  authToken: null,
  userBusinesses: [],
  selectedBusiness: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => ({ ...state, authToken: action.payload }),
    setUserBusinesses: (state, action: PayloadAction<BusinessDTO[]>) => ({ ...state, userBusinesses: action.payload }),
    setSelectedBusiness: (state, action: PayloadAction<BusinessDTO>) => {
      const result = Cookies.set('selectedBusiness', JSON.stringify(action.payload));
      Cookies.set('selectedBusinessId', `${action.payload.id}`);
      console.log({ result });
      return { ...state, selectedBusiness: action.payload };
    },
  },
});

export const { setAuthToken, setUserBusinesses, setSelectedBusiness } = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
