import TicketDTO from '../models/ticket';

interface Props {
  index: number;
  customizeData: boolean;
  item: TicketDTO;
}

const SectionTitle: React.FC<Props> = ({ customizeData, index, item }) => (
  <div className="flex items-center px-1 flex-grow space-x-4 text-gray-600 mb-2">
    {customizeData && (
      <span className="text-lg font-medium">Entrada {index + 1}</span>
    )}
    {!customizeData && (
      <span className="text-lg font-medium">Datos de las entradas</span>
    )}
    {customizeData && (
      <span className="text-sm ml-2">{item.name}</span>
    )}
    <div className="flex-grow h-px bg-gray-600 ml-4"></div>
  </div>
);

export default SectionTitle;
