import RenderStageMap from '../components/RenderStageMap';
import { useGetSeatsInfoQuery } from '../store/apis/events';

const SeatSelectionPage = () => {
  const { data: questions, isLoading } = useGetSeatsInfoQuery(
    { eventId: 0, businessId: 0 },
  );
  const handleSeatsSelected = (selectedSeats: number[]) => {
    console.log(selectedSeats);
  };

  return (
    <div>
      {!isLoading && questions && <RenderStageMap seatsInfo={questions} onSeatsUpdate={handleSeatsSelected}/>}
    </div>
  );
};

export default SeatSelectionPage;
