const useSendWhatsapp = (number: string, msg: string) => {
  const encodedMsg = encodeURIComponent(msg);
  const url = `https://api.whatsapp.com/send?phone=${number}&text=${encodedMsg}`;
  const newWindow = window.open(url);

  if (!newWindow) {
    window.location.href = url;
  }

  return newWindow;
};

export default useSendWhatsapp;
