import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormControlField from './FormControlField';
import Button from './Button';
import {
  useAddEventTransactionMutation,
} from '../store';
import { toastSucces } from '../utils/toasts';

interface Props {
  businessId: number;
  eventId: number;
  sellerId: number;
  onSuccess: () => void;
}

interface FormData {
  amount: number;
  description: string;
}

const validationSchema = yup.object<FormData>().shape({
  amount: yup
    .number()
    .required('El monto es requerido')
    .min(0, 'El monto debe ser mayor a 0'),
  description: yup
    .string()
    .required('La descripción es requerida'),
});

const AddUserExpense: React.FC<Props> = ({
  businessId, eventId, sellerId, onSuccess,
}) => {
  const form = useForm<FormData>({
    resolver: yupResolver<FormData>(validationSchema),
  });

  const { control, handleSubmit, formState } = form;

  const [addEventTransaction, addEventTransactionResults] = useAddEventTransactionMutation();

  const handleAddExpense = async (data: FormData) => {
    try {
      const result = await addEventTransaction({
        businessId,
        eventId,
        transactionData: {
          user_id: sellerId,
          is_seller_expense: true,
          amount: data.amount,
          type: 'SELLER_EXPENSE',
          description: data.description,
        },
      });

      if ('data' in result) {
        toastSucces('Gasto agregado correctamente');
        onSuccess();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <form className="w-full h-full" onSubmit={handleSubmit(handleAddExpense)}>
      <div className="mb-5">
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <FormControlField
                label="Observación"
                labelAbove={true}
                id="description"
                {...field}
                errMsg={formState.errors.description?.message}
              />
            )}
          />
        </div>
        <div className="mb-5">
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <FormControlField
                label="Monto"
                labelAbove={true}
                id="amount"
                type="number"
                {...field}
                errMsg={formState.errors.amount?.message}
              />
            )}
          />
        </div>
        <div className="flex justify-center align-items-center">
          <Button
            className="w-full"
            primary
            loading={addEventTransactionResults.isLoading}
          >
            Agregar gasto
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddUserExpense;
