import { createApi } from '@reduxjs/toolkit/query/react';
import TicketDTO, { CreateTicketDTO, TicketReservation, UpdateTicketDTO } from '../../models/ticket';
import { Response } from '../../models/shared';
import { transformResponseData } from '../utils';
import baseQueryWithReauth from './base';

const ticketsApi = createApi({
  reducerPath: 'ticketsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['tickets'],
  endpoints: (build) => ({
    getTicketById: build.query<Response<TicketDTO>, { businessId: number, eventId: number, ticketId: number }>({
      query: ({ ticketId, businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/tickets/${ticketId}`,
        method: 'GET',
      }),
    }),
    getAllTickets: build.query<(TicketDTO[] | null), { businessId: number, eventId: number, onlyActive?: boolean }>({
      providesTags: ['tickets'],
      query: ({ businessId, eventId, onlyActive }) => ({
        url: `/businesses/${businessId}/events/${eventId}/tickets`,
        method: 'GET',
        params: onlyActive !== undefined ? {
          active: onlyActive,
        } : undefined,
      }),
      transformResponse: (response: Response<TicketDTO[]>): (TicketDTO[] | null) => response.data || null,
    }),
    createTicket: build.mutation<Response<TicketDTO>, { ticket: CreateTicketDTO, businessId: number, eventId: number }>({
      query: ({
        ticket, businessId, eventId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/tickets`,
        method: 'POST',
        body: ticket,
      }),
      invalidatesTags: ['tickets'],
    }),
    updateTicket: build.mutation<Response<TicketDTO>, { ticket: UpdateTicketDTO, businessId: number, eventId: number }>({
      query: ({
        ticket, businessId, eventId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/tickets`,
        method: 'PUT',
        body: ticket,
      }),
      invalidatesTags: ['tickets'],
    }),
    deleteTicket: build.mutation<TicketDTO | null, { businessId: number, eventId: number, ticketId: number }>({
      query: ({
        ticketId, businessId, eventId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/tickets/${ticketId}`,
        method: 'DELETE',
      }),
      transformResponse: transformResponseData,
      invalidatesTags: ['tickets'],
    }),
    reserveTickets: build.mutation<Response<{ valid: boolean }>, { businessId: number, eventId: number, reservations: TicketReservation[] }>({
      query: ({
        businessId, eventId, reservations,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/tickets/reservations`,
        method: 'POST',
        body: { reservations },
      }),
      invalidatesTags: ['tickets'],
    }),
  }),
});

export const {
  useGetTicketByIdQuery, useGetAllTicketsQuery, useCreateTicketMutation, useUpdateTicketMutation, useDeleteTicketMutation, useReserveTicketsMutation,
} = ticketsApi;
export { ticketsApi };
