import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { AuthUserData } from '../../models/user';

interface AuthState {
  token: string | null;
  user: AuthUserData;
}

const dummyUser: AuthUserData = {
  id: 0,
  username: 'user',
  type: 'COMMON',
  email: 'info@avantti.io',
  user_businesses: [],
  user_events: [],
};

const initialState: AuthState = {
  token: null,
  user: dummyUser,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<{ user: AuthUserData; token: string }>) => {
      Cookies.set('token', action.payload.token);
      Cookies.set('userData', JSON.stringify(action.payload.user));
      return { ...state, user: action.payload.user, token: action.payload.token };
    },
    logout: (state) => {
      Cookies.remove('token');
      Cookies.remove('userData');
      return { ...state, user: dummyUser, token: null };
    },
    loadAuth: (state) => {
      const user: AuthUserData = JSON.parse(Cookies.get('userData') || '{}');
      const token = Cookies.get('token');
      if (user && token) {
        return { ...state, user, token };
      }
      return state;
    },
  },
});

export const { logout, loginSuccess, loadAuth } = authSlice.actions;

export const authReducer = authSlice.reducer;
