interface Props {
  icon: React.FC;
  field: string;
  value: string | number;
}

const IconFieldValue: React.FC<Props> = ({ icon, field, value }) => (
  <div className="flex flex-col text-lg">
    <span className="flex items-center mb-1">
      {icon({ className: 'mr-1 text-xl' })}
      <span className="font-medium mr-1">{field}: </span>
      <span className="text-secondary">
        {value}
      </span>
    </span>
  </div>
);

export default IconFieldValue;
