import { useState } from 'react';

export interface ColumnConfig<T> {
  label: string;
  render: (data: T) => React.ReactNode;
  header?: () => React.ReactNode;
  sortValue?: (data: T) => string | number;
}

interface UseSortResult<T> {
  sortOrder: 'asc' | 'desc' | null;
  sortBy: string | null;
  sortAttribute: (label: string) => void;
  dataSorted: T[];
}

function useSort<T>(config: ColumnConfig<T>[], data: T[]): UseSortResult<T> {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);
  const [sortBy, setSortBy] = useState<string | null>(null);

  const sortAttribute = (label: string) => {
    if (sortBy && sortBy !== label) {
      setSortOrder('asc');
      setSortBy(label);
      return;
    }

    if (sortOrder === null) {
      setSortOrder('asc');
      setSortBy(label);
    } else if (sortOrder === 'asc') {
      setSortOrder('desc');
      setSortBy(label);
    } else if (sortOrder === 'desc') {
      setSortOrder(null);
      setSortBy(null);
    }
  };

  let dataSorted = [...data];
  if (sortOrder && sortBy) {
    const columnToSort = config.find((conf) => conf.label === sortBy);
    if (columnToSort) {
      const { sortValue } = columnToSort;
      if (sortValue) {
        dataSorted = [...data].sort((a, b) => {
          const valueA = sortValue(a);
          const valueB = sortValue(b);
          const reversed = sortOrder === 'asc' ? 1 : -1;
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB) * reversed;
          } if (typeof valueA === 'number' && typeof valueB === 'number') {
            return (valueA - valueB) * reversed;
          }
          throw new Error('data type cannot be sorted');
        });
      }
    }
  }

  return {
    sortOrder, sortBy, sortAttribute, dataSorted,
  };
}

export default useSort;
