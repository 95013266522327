import EventDTO from '../models/event';
import { OrderDTO, OrderItemData } from '../models/order';
import { formatCurrency } from './shared';

const generateWhatsappMessage = (event: EventDTO, order: OrderDTO, orderItems: OrderItemData[], totalToPay: number) => `Hola, soy *${orderItems[0].name} ${orderItems[0].last_name}*

Quisiera comprar ${orderItems.length > 1
    ? `${orderItems.length} entradas`
    : 'una entrada'
} para *${event.name}*

Datos de los tickets:
${orderItems.map(
    (item) => `- *${item.name} ${item.last_name}* - DNI: *${item.person_id}*
`,
  )}
Número de órden: *${order.display_id}*
Total: *${formatCurrency(totalToPay)}*

¡Gracias!
  `.replaceAll(',-', '-');

export default generateWhatsappMessage;
