export type UserType = 'ROOT' | 'COMMON';
export type UserRole = UserPermissions | 'ROOT';

export type UserPermissions = 'ADMIN' | 'EDITOR' | 'SCANNER' | 'SELLER';
export const userPermissionsArray = ['ADMIN', 'EDITOR', 'SCANNER', 'SELLER'];

export const displayUserPermissions = {
  ADMIN: 'Administrador',
  EDITOR: 'Editor',
  SCANNER: 'Escaner',
  SELLER: 'Vendedor',
};

export interface UserBusiness {
  id: number;
  business_id: number;
  user_id: number;
  active: boolean;
  permissions: UserPermissions;
}

export interface UserEvent {
  id: number;
  event_id: number;
  user_id: number;
  active: boolean;
  permissions: UserPermissions;
  seller_comission_rate: number
}

export interface AuthUserData {
  id: number;
  username: string,
  type: UserType,
  email: string
  user_businesses: UserBusiness[]
  user_events: UserEvent[]
}
export default interface UserDTO {
  id: number;
  email: string;
  username: string;
  firebaseId: string;
  type: UserType;
  is_verified: boolean;
  verification_code: string;
  user_businesses: UserBusiness[];
  user_events: UserEvent[];
}

export type UserWithBusiness = UserDTO & { user_business: UserBusiness };
export type UserWithBusinessAndEvent = UserDTO & { user_business: UserBusiness, user_event: UserEvent };
