import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { RootState, loadAuth } from '..';

interface RequireAuthProps {
  children: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const location = useLocation();
  const { token } = useSelector((state: RootState) => state.auth);
  const [firstTime, setFirstTime] = useState(true);
  const dispatch = useDispatch();

  // in case the app is reloaded directly at a protected component
  useEffect(() => {
    if (!token && firstTime) {
      dispatch(loadAuth());
      setFirstTime(false);
    }
  }, [token, firstTime]);

  useEffect(() => {
    if (!token && !firstTime) {
      toast.error('La sesión ha expirado, por favor inicia sesión nuevamente', { position: 'bottom-center', duration: 5000 });
    }
  }, [token, firstTime]);

  if (!token && !firstTime) {
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  return children;
};

export default RequireAuth;
