import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../store';

const useGetUserRole = () => {
  const { business_id: businessId } = useParams();
  if (!businessId || !+businessId) {
    throw new Error('no business_id');
  }
  const { user: userData } = useSelector(
    (state: RootState) => state.auth,
  );
  return userData.type === 'ROOT' ? 'ROOT' : userData.user_businesses.find((ub) => ub.business_id === +businessId)?.permissions || 'SCANNER';
};

export default useGetUserRole;
