import Button from '../components/Button';
import LoadingLottie from '../components/LoadingLottie';
import TextTitleCard from '../components/TitleTextCard';
import useQuery from '../hooks/useQuery';
import {
  useGetUserByConfirmationCodeQuery,
  useVerifyUserMutation,
} from '../store';

const SignUpConfirmPage: React.FC = () => {
  const query = useQuery();
  const confirmationCode = query.get('code');
  const { data: user, isLoading: userLoading } = useGetUserByConfirmationCodeQuery(
    { confirmationCode: confirmationCode || '' },
    { skip: !confirmationCode },
  );
  const [verifyUser, resultVerify] = useVerifyUserMutation();

  const handleVerifyClick = () => {
    if (user && confirmationCode) {
      verifyUser({ userId: user.id, code: confirmationCode });
    }
  };

  if (userLoading) {
    return <LoadingLottie />;
  }

  return (
    <div className="flex justify-center align-items-center">
      {user && !user.is_verified && (
        <TextTitleCard title="Confirma tu registro">
          <div className="flex justify-center align-items-center">
            <Button
              loading={resultVerify.isLoading}
              primary
              onClick={handleVerifyClick}
            >
              Confirmar
            </Button>
          </div>
        </TextTitleCard>
      )}
      {user && user.is_verified && (
        <TextTitleCard title="¡Usuario confirmado!">
          Tu correo {user.email} ha sido confirmado.
        </TextTitleCard>
      )}
      {!user && (
        <TextTitleCard title="Usuario no encontrado">
          No pudimos encontrar un usuario vinculado a este código.
        </TextTitleCard>
      )}
    </div>
  );
};

export default SignUpConfirmPage;
