import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const FormCardFrame: React.FC<Props> = ({ children }) => (
  <div className='p-3 bg-white rounded-lg space-y-1.5 shadow'>
    {children}
    </div>
);
export default FormCardFrame;
