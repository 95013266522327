import React from 'react';

interface RadioButtonProps {
  name: string;
  value: string;
  checked: boolean;
  label: string;
  description?: string; // Optional description prop
  disabled?: boolean;
  onChange: (value: string) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  name, value, checked, label, description, onChange, disabled,
}) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <div className="flex flex-col">
      <label className="flex items-center space-x-3 cursor-pointer">
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          className="form-radio text-blue-600 h-4 w-4"
        />
        <span className="text-gray-700">{label}</span>
      </label>
      {description && checked && <span className="text-gray-500 text-xs ml-7 mt-0">{description}</span>}
    </div>
  );
};

export default RadioButton;
