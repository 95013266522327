interface Props {
  icon: React.FC;
  title: string;
  info?: string;
  iconColor?: string;
  body?: React.ReactNode,
  bgColor?: string,
}

const IconInfoCard: React.FC<Props> = ({
  icon, iconColor, info, title, body, bgColor,
}) => (
  <div className={`flex flex-col justify-center items-center h-30 p-1 ${bgColor}`}>
    <div className="h-8 w-8 mb-3">{icon(({ className: `${iconColor} h-8 w-8 mb-5` }))}</div>
    <h1 className="text-lg font-medium text-center mb-3.5">{title}</h1>
    {info && <p className="text-center text-gray-500 mb-5">{info}</p>}
    {body}
  </div>
);

export default IconInfoCard;
