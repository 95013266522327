// LoadingLottie.tsx
import React from 'react';
import LottieAnimation from './LottieAnimation';

const LoadingLottie: React.FC = () => (
  <div className="h-full flex flex-col justify-center items-center">
    <LottieAnimation
      className="h-10 w-10"
      width="125px"
      height="125px"
      path="/loading_lottie.json"
    />
  </div>
);

export default LoadingLottie;
