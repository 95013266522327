import toast from 'react-hot-toast';

const toastSuccess = (msg: string) => {
  toast.dismiss();
  toast.success(msg, {
    duration: 5000,
    position: 'top-center',
  });
};

const toastError = (msg: string) => {
  toast.dismiss();
  toast.error(msg, {
    duration: 5000,
    position: 'top-center',
  });
};

const toastInfo = (msg: string) => {
  toast.dismiss();
  toast(msg, {
    duration: 5000,
    position: 'top-center',
  });
};

export { toastSuccess as toastSucces, toastError, toastInfo };
