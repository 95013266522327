import classNames from 'classnames';
import { GoSync } from 'react-icons/go';
import React, { FC, ReactNode } from 'react';
import './Button.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  primary?: boolean;
  transparent?: boolean;
  secondary?: boolean;
  custom?: boolean;
  success?: boolean;
  warning?: boolean;
  danger?: boolean;
  outline?: boolean;
  rounded?: boolean;
  loading?: boolean;
}

const Button: FC<ButtonProps> = ({
  children,
  primary,
  secondary,
  custom,
  success,
  warning,
  danger,
  transparent,
  outline,
  rounded,
  loading,
  ...rest
}) => {
  const classes = classNames(
    rest.className,
    'flex justify-center items-center px-3 py-1.5 border font-medium',
    'rounded-lg',
    {
      'opacity-80': loading,
      'border-gray-900': primary && !rest.disabled,
      'border-gray-300 bg-white text-gray-900': secondary && !rest.disabled,
      'border-green-500': success,
      'border-yellow-400': warning,
      'border-red-500': danger,
      'border-hidden': transparent,
      'custom-button': custom && !rest.disabled,
      'custom-disabled': custom && rest.disabled,
      'border-gray-300 bg-gray-400 text-gray-50': primary && rest.disabled,
      'bg-gray-50 text-gray-400': secondary && rest.disabled,
      'text-secondary': transparent,
      'bg-gray-900': primary && !outline && !rest.disabled,
      'bg-green-500': success && !outline,
      'bg-yellow-400': warning && !outline,
      'bg-red-600': danger && !outline,
      'bg-white': outline,
      'bg-red-100': custom && rest.disabled,

      'text-gray-50':
        !outline && (primary || success || warning || danger),
      'text-sky-500': outline && primary,
      'text-green-500': outline && success,
      'text-yellow-400': outline && warning,
      'text-red-500': outline && danger,

      'rounded-full': rounded,
    },
  );
  return (
    <button {...rest} className={classes} disabled={loading || rest.disabled}>
    {loading
      && <GoSync className='animate-spin' />
    }
    <span className={`${loading ? 'ml-2' : ''}`}>{children}</span>
  </button>
  );
};

export default Button;
