import { PiCalendarBlank } from 'react-icons/pi';
import { CiLocationOn } from 'react-icons/ci';
import EventDTO from '../models/event';
import Button from './Button';
import CardFrame from './CardFrame';
import { parseFriendlyDate } from '../utils/dates';

interface EventCardProps {
  event: EventDTO;
  onSelect: (eventId: number) => void;
}

const LandingEventCard: React.FC<EventCardProps> = ({ event, onSelect }) => (
    <CardFrame>
      <div className="flex flex-col items-center w-full">
        <div className="w-full">
          <img
            src={
              event.banner_image
              || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMsG_C_QzoMZzPjUrOATtYljG9I3dBybHwjS7yAuDw1w&s'
            }
            alt={event.name}
            className="h-[11rem] md:h-[19rem] w-full h-36 object-cover rounded-lg"
          />
        </div>

        <div className="flex flex-col items-center w-full p-4">
          <h2 className="text-xl font-semibold">{event.name}</h2>
          {event.business && < p className="text-gray-600 text-sm mb-2">Organiza: {event.business.name}</p>}
          <hr className="w-full mb-3"/>
          <div className="w-full">
            <p className="flex items-center text-gray-600">
              <PiCalendarBlank className="mr-1 text-sm" />
              {parseFriendlyDate(event.date, true)} - {event.time}
            </p>
            <p className="flex items-center text-gray-600">
              <CiLocationOn className="mr-1 text-sm" />
              {event.place}
            </p>
          </div>
          <Button
            primary
            disabled={!event.active}
            onClick={() => onSelect(event.id)}
            className="w-44 h-12 flex justify-center items-center mt-4 p-5"
          >
            Adquirir entradas
          </Button>
        </div>
      </div>
    </CardFrame>
);

export default LandingEventCard;
