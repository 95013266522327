import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import AppInitialization from './store/utils/AppInitialization';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppInitialization>
        <RouterProvider
          router={createBrowserRouter([{ path: '*', element: <App /> }])}
        />
      </AppInitialization>
    </Provider>
  </React.StrictMode>,
);
