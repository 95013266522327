import { useState } from 'react';
import SignUpForm from '../components/SignUpForm';
import UserDTO from '../models/user';

const SignUpPage: React.FC = () => {
  const [createdUser, setCreatedUser] = useState<UserDTO | null>(null);
  const handleSuccess = (user:UserDTO) => {
    setCreatedUser(user);
  };
  return <div>
    {!createdUser && <SignUpForm onSuccess={handleSuccess} />}
    {createdUser && <div>Tu registro se proceso con éxito. Enviamos un mail de confirmación a {createdUser.email}</div>}
  </div>;
};

export default SignUpPage;
