import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const CreateOrderSuccessSkeleton: React.FC = () => (
  <SkeletonTheme baseColor="#E5E7EB" highlightColor="#F3F4F6">
    <div className="event-colors h-full px-3 mt-5 text-center">
      <div className="px-3 mt-5">
        <Skeleton height={140} width="90%" />
      </div>
      <div className="px-3 mt-5">
        <Skeleton height={20} width="30%" />
      </div>
      <div className="px-3 mt-5">
        <Skeleton height={20} width="80%" />
      </div>
      <div className="px-3 mt-5">
        <Skeleton height={100} width="90%" />
      </div>
      <div className="w-full py-3 mt-8 px-3 text-center">
        <Skeleton height={20} width="80%" />
      </div>
    </div>
  </SkeletonTheme>
);

export default CreateOrderSuccessSkeleton;
