import { createApi } from '@reduxjs/toolkit/query/react';
import { Response, UpdateDTO } from '../../models/shared';
import { transformResponseData } from '../utils';
import CouponDTO, { CreateCouponDTO } from '../../types/Coupon';
import { CouponValidation } from '../../types/CouponValidation';
import baseQueryWithReauth from './base';

const couponsApi = createApi({
  reducerPath: 'couponsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['coupons'],
  endpoints: (build) => ({
    getCouponById: build.query<Response<CouponDTO>, { businessId: number, eventId: number, couponId: number }>({
      query: ({ couponId, businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/coupons/${couponId}`,
        method: 'GET',
      }),
    }),
    getAllCoupons: build.query<(CouponDTO[] | null), { businessId: number, eventId: number }>({
      providesTags: ['coupons'],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/coupons`,
        method: 'GET',
      }),
      transformResponse: (response: Response<CouponDTO[]>): (CouponDTO[] | null) => response.data || null,
    }),
    createCoupon: build.mutation<Response<CouponDTO>, { coupon: CreateCouponDTO, businessId: number, eventId: number, }>({
      query: ({
        coupon, businessId, eventId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/coupons`,
        method: 'POST',
        body: coupon,
      }),
      invalidatesTags: ['coupons'],
    }),
    updateCoupon: build.mutation<Response<CouponDTO>, { coupon: UpdateDTO<CouponDTO>, businessId: number, eventId: number }>({
      query: ({
        coupon, businessId, eventId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/coupons`,
        method: 'PUT',
        body: coupon,
      }),
      invalidatesTags: ['coupons'],
    }),
    deleteCoupon: build.mutation<CouponDTO | null, { businessId: number, eventId: number, couponId: number }>({
      query: ({
        couponId, businessId, eventId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/coupons/${couponId}`,
        method: 'DELETE',
      }),
      transformResponse: transformResponseData,
      invalidatesTags: ['coupons'],
    }),
    checkCouponValidation: build.mutation<Response<CouponValidation>, { businessId: number, eventId: number, amount: number, couponCode: string }>({
      query: ({
        businessId, eventId, couponCode, amount,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/coupons/validation`,
        method: 'POST',
        body: { code: couponCode, amount },
      }),
    }),
  }),
});

export const {
  useGetCouponByIdQuery, useGetAllCouponsQuery, useCreateCouponMutation, useUpdateCouponMutation, useDeleteCouponMutation, useCheckCouponValidationMutation,
} = couponsApi;
export { couponsApi };
