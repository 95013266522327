import 'react-loading-skeleton/dist/skeleton.css';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const OrdersTableSkeleton: React.FC = () => (
  <SkeletonTheme baseColor="#E5E7EB">
    <div className="flex items-center p-1 bg-white shadow-md">
      <Skeleton circle={true} height={20} width={20} />
      <Skeleton className="ml-1 flex-grow rounded-full h-4" />
    </div>
    {/* Table */}
    <div className="overflow-y-auto">
      <table className="table-auto border-spacing-2 w-full">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-3">
              <Skeleton width={20} />
            </th>
            <th className="p-3">
              <Skeleton width={'80%'} />
            </th>
            <th className="p-3">
              <Skeleton width={40} />
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(5)].map((_, i) => (
            <tr key={i} className="border-b">
              <td className="p-3">
                <Skeleton circle={true} height={20} width={20} />
              </td>
              <td className="p-3">
                <Skeleton width={'80%'} />
              </td>
              <td className="p-3">
                <Skeleton width={40} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    {/* Buttons */}
    <div className="flex justify-center align-items-center mt-4">
      <Skeleton className="mr-4 w-24 h-8 rounded-lg" />
      <Skeleton className="w-24 h-8 rounded-lg" />
    </div>
  </SkeletonTheme>
);

export default OrdersTableSkeleton;
