import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import toast from 'react-hot-toast';
import SortableTable from './SortableTable';
import { UserWithBusinessAndEvent } from '../models/user';
import MenuDropdown from './MenuDropdown';
import { useDeleteUserEventMutation } from '../store';

interface Props {
  users: UserWithBusinessAndEvent[];
  businessId: number;
  eventId: number;
}

const SellersTable: React.FC<Props> = ({ users, businessId, eventId }) => {
  const [deleteUserEvent, resultsDelete] = useDeleteUserEventMutation();

  const handleDeleteUserEvent = async (user: UserWithBusinessAndEvent) => {
    toast.promise(
      deleteUserEvent({
        businessId,
        eventId: +eventId,
        userEventId: user.user_event.id,
      }).unwrap(),
      {
        loading: 'Eliminando...',
        success: <b>Vendedor eliminado con éxito</b>,
        error: <b>Error al eliminar vendedor.</b>,
      },
    );
  };

  const dropDownOptions = [
    {
      label: 'Eliminar',
      onClick: handleDeleteUserEvent,
    },
  ];

  const config = [
    {
      label: 'Usuario',
      render: (user: UserWithBusinessAndEvent) => user.username,
    },
    {
      label: 'Comisión',
      render: (user: UserWithBusinessAndEvent) => `${user.user_event.seller_comission_rate * 100}%`,
    },
    {
      label: 'Activo',
      render: (user: UserWithBusinessAndEvent) => <div className='flex justify-center items-center'> {user.user_event.active ? <AiFillCheckCircle className=' text-green-600' /> : <AiFillCloseCircle className='text-red-600' />}</div>,
    },
    {
      label: '',
      render: (item: UserWithBusinessAndEvent) => (
        <div>
          <MenuDropdown<UserWithBusinessAndEvent>
            options={dropDownOptions}
            data={item}
            loading={resultsDelete.isLoading}
          />
        </div>
      ),
    },
  ];

  const keyFn = (user: UserWithBusinessAndEvent) => `${user.id}`;

  return (
    <div>
      <SortableTable data={users} config={config} keyFn={keyFn} pagination={false} search={false}/>
    </div>
  );
};

export default SellersTable;
