import Button from './Button';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  addButtonAction?: () => void | undefined;
}

const TextTitleCard: React.FC<Props> = ({ title, children, addButtonAction }) => (
  <div className="rounded-lg p-4 bg-white shadow w-full">
    <div className="flex justify-between">
      {title && <h2 className="text-lg font-medium mb-2">{title}</h2>}
      {addButtonAction && <Button secondary onClick={addButtonAction}>+</Button>}
    </div>
      {title && <hr className="mb-3" />}
    <div className="flex flex-col text-md">{children}</div>
  </div>
);

export default TextTitleCard;
