import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { loginSuccess, logout, useGetUserByFirebaseIdQuery } from '../store';

const useAuthObserver = () => {
  const dispatch = useDispatch();
  const [firebaseId, setFirebaseId] = useState('');

  const { data: userData, isLoading: isUserDataLoading } = useGetUserByFirebaseIdQuery(
    { firebaseId },
    { skip: !firebaseId },
  );

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setFirebaseId(user.uid);
      } else {
        setFirebaseId('');
        dispatch(logout());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    if (userData && firebaseId) {
      getAuth().currentUser?.getIdToken().then((token) => {
        dispatch(loginSuccess({ user: userData, token }));
      });
    }
  }, [userData, firebaseId, dispatch]);

  return { isUserDataLoading };
};

export default useAuthObserver;
