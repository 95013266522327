import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, useGetEventByIdQuery } from '../store';
import { setSelectedEvent } from '../store/slices/events';

const useGetSelectedEvent = () => {
  const dispatch = useDispatch();
  const { event_id: eventId } = useParams<{ event_id: string }>();
  const selectedEvent = useSelector((state: RootState) => state.events.selectedEvent);
  const { data: event, isLoading: eventLoading } = useGetEventByIdQuery({ eventId: +(eventId || 0) }, { skip: !eventId || !!selectedEvent });
  if (event && !selectedEvent) {
    dispatch(setSelectedEvent(event));
  }
  return { event: event || selectedEvent, eventLoading };
};

export default useGetSelectedEvent;
