import { Fragment, useEffect, useState } from 'react';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import { FaUserAltSlash } from 'react-icons/fa';
import useGetParams from '../hooks/useGetParams';
import SellersTable from '../components/SellersTable';
import UserDTO, {
  UserWithBusiness,
  UserWithBusinessAndEvent,
} from '../models/user';
import {
  useGetBusinessUsersQuery,
  useGetEventUsersQuery,
  useGetSellerSummaryQuery,
} from '../store';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import IconInfoCard from '../components/IconInfoCard';
import Button from '../components/Button';
import CustomModal from '../components/CustomModal';
import AddSellerToEvent from '../components/AddSellerToEvent';
import SellerSummary from '../components/SellerSummary';
import LoadingLottie from '../components/LoadingLottie';

const getEventSellers = (users: UserDTO[], eventId: number) => users
  .map((u) => ({
    ...u,
    user_business: u.user_businesses.find(
      (ub) => ub.permissions === 'SELLER',
    ),
    user_event: u.user_events.find((ue) => ue.event_id === eventId),
  }))
  .filter(
    (u): u is UserWithBusinessAndEvent => u.user_business !== undefined && u.user_event !== undefined,
  )
  .sort((a, b) => a.username.localeCompare(b.username));

const getSellersNotInEvent = (
  users: UserDTO[],
  businessId: number,
  eventId: number,
) => {
  const bizUsers: UserWithBusiness[] = users
    .map((u) => ({
      ...u,
      user_business: u.user_businesses.find(
        (ub) => ub.business_id === businessId,
      ),
    }))
    .filter((u): u is UserWithBusiness => u.user_business !== undefined);
  const sellerUsers = bizUsers.filter(
    (u) => u.user_business.permissions === 'SELLER',
  );
  const sellersNotInEvent = sellerUsers.filter(
    (u) => !u.user_events.find((ue) => ue.event_id === eventId),
  );
  return sellersNotInEvent;
};

const AdminSellersPage = () => {
  const { eventId, businessId } = useGetParams();
  const [eventSellers, setEventSellers] = useState<UserWithBusinessAndEvent[]>(
    [],
  );
  const [showModal, setShowModal] = useState(false);
  const [sellersNotInEvent, setSellersNotInEvent] = useState<UserDTO[]>([]);

  const { data: sellersSummary, isLoading: isLoadingSellerSummary } = useGetSellerSummaryQuery({ businessId, eventId });

  const { data: eventUsers, isFetching: isFetchingEventUsers } = useGetEventUsersQuery({
    businessId,
    eventId,
  });

  const {
    data: bizUsers,
    isFetching: isFetchingBizUsers,
    refetch: refetchBizUsers,
  } = useGetBusinessUsersQuery({
    businessId,
  });

  useEffect(() => {
    if (eventUsers) {
      setEventSellers(getEventSellers(eventUsers, eventId));
      refetchBizUsers();
    }
  }, [eventUsers]);

  useEffect(() => {
    if (bizUsers) {
      setSellersNotInEvent(getSellersNotInEvent(bizUsers, businessId, eventId));
    }
  }, [bizUsers]);

  const handleAddSeller = () => {
    setShowModal(true);
  };

  const handleSucessInviteUser = () => {
    setShowModal(false);
  };

  return (
    <div className="px-3 h-screen">
      <div>
        <h1 className="text-xl font-medium mb-4">
          Configuración de vendedores
        </h1>
        <div className="card border border-gray-200">
          <div className="flex py-2 justify-between">
            <h1 className="text-lg">Vendedores</h1>
            <Button onClick={handleAddSeller} secondary>
              {' '}
              +{' '}
            </Button>
          </div>
          {(isFetchingEventUsers || isFetchingBizUsers) && (
            <OrdersTableSkeleton />
          )}{' '}
          {eventSellers && eventSellers.length > 0 && (
            <SellersTable
              users={eventSellers}
              businessId={businessId}
              eventId={eventId}
            />
          )}
          {eventSellers && eventSellers.length === 0 && (
            <IconInfoCard
              title="Aún no hay vendedores en este evento"
              info="Aquí aparecerán los vendedores de este evento"
              icon={HiOutlineRocketLaunch}
              iconColor="text-indigo-600"
            />
          )}
        </div>
        <CustomModal
          onRequestClose={() => setShowModal(false)}
          isOpen={showModal}
          actionBar={<div></div>}
        >
          <Fragment>
            <div className="flex flex-col justify-center items-center mb-5">
              <h1 className="text-xl font-medium mb-4">Agregar vendedor</h1>
              <p className="text-gray-400 text-sm text-center">
                Podrás agregar vendedores a este evento
              </p>
            </div>
            {sellersNotInEvent.length > 0 && (
              <AddSellerToEvent
                businessId={businessId}
                eventId={eventId}
                users={sellersNotInEvent}
                onSuccess={handleSucessInviteUser}
              />
            )}
            {sellersNotInEvent.length === 0 && (
              <IconInfoCard
                title="No hay más vendedores en esta organización"
                info="Debes agregar vendedores a la organización para poder agregarlos a este evento"
                icon={FaUserAltSlash}
                iconColor="text-indigo-600"
              />
            )}
          </Fragment>
        </CustomModal>
      </div>
      {eventSellers.length > 0 && (
        <div>
          <h1 className="text-xl font-medium mb-4 mt-6">Reporte de vendedores</h1>
          <div>
            {isLoadingSellerSummary && <LoadingLottie />}
            {sellersSummary?.map((seller) => (
              <div className="mb-4" key={seller.seller_id}>
                <SellerSummary summary={seller} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSellersPage;
