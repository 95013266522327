import { FiUserCheck, FiWatch } from 'react-icons/fi';
import { IoLocationOutline, IoMapOutline } from 'react-icons/io5';
import { HiOutlineCalendar } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useGetEventByIdQuery } from '../store';
import { parseFriendlyDate } from '../utils/dates';
import EventPageSkeleton from '../components/EventPageSkeleton';
import Image from '../components/Image';
import { setSelectedEvent } from '../store/slices/events';
import Button from '../components/Button';
import TextTitleCard from '../components/TitleTextCard';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
// import EventPageNasty from './EventPageNasty';

interface Props {
  loadedEventId?: number;
}

const EventPage: React.FC<Props> = ({ loadedEventId }) => {
  const { event_id: eventId } = useParams();
  const eventIdToUse = loadedEventId || +(eventId || 0);
  const { data: event, isLoading: eventLoading } = useGetEventByIdQuery(
    { eventId: eventIdToUse },
    { skip: !eventIdToUse },
  );
  const business = event?.business;
  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();

  const [clickedGetTickets, setClickedGetTickets] = useState(false);

  useEffect(() => {
    if (event) {
      dispatch(setSelectedEvent(event));
    }
  }, [business, event]);

  const tickets = event?.tickets?.filter((t) => t.active);

  if (eventLoading) {
    return <EventPageSkeleton />;
  }

  if (!business || !event || !tickets) {
    navigate('..');
    console.error('missing required data', { business, event, tickets });
    return <div></div>;
  }

  // if (business.id === 1) {
  //   return <EventPageNasty />;
  // }

  const importantInfo = event.terms
    ? event.terms.split('\n').map((str, index, array) => (
        <div key={index} className="mb-1">
          {str}
          {index !== array.length - 1 && <br />}
        </div>
    ))
    : null;

  const eventDescription = event.description
    .split('\n')
    .map((str, index, array) => (
      <div key={index} className="mb-1">
        {str}
        {index !== array.length - 1 && <br />}
      </div>
    ));

  if (!event || !business) {
    throw new Error('no event, business');
  }

  const handleClickBuyTickets = () => {
    setClickedGetTickets(true);
    navigate('orders/new/tickets');
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-center mb-3">
        <Image
          src={event.banner_image}
          height="250px"
          width="100%"
          className="max-w-full"
        />
      </div>
      <div className="flex flex-col justify-center items-center mb-3">
        <h1>{event.name}</h1>
        <h2 className="text-md">Organiza: {business.name}</h2>
      </div>
      <div className="flex justify-center align-items-center">
        <div className="w-11/12 bg-gray-100 mb-7 rounded-lg">
          <TextTitleCard>
            <div className="text-center">{eventDescription}</div>
          </TextTitleCard>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-11/12">
          <TextTitleCard title="Información general">
            <div className="flex flex-col text-lg">
              <span className="flex items-center mb-1">
                <HiOutlineCalendar className="mr-1 text-xl" />
                <span className="font-medium mr-1">Fecha: </span>
                <span className="text-secondary">
                  {parseFriendlyDate(event.date, true)}
                </span>
              </span>
              <span className="flex items-center mb-1">
                <FiWatch className="mr-1 text-xl" />
                <span className="font-medium mr-1">Hora: </span>
                <span className="text-secondary">{event.time}hs</span>
              </span>
              <span className="flex items-center mb-1">
                <IoLocationOutline className="mr-1 text-xl" />
                <span>
                  <span className="font-medium mr-1">Lugar: </span>
                  <span className="text-secondary">{event.place}</span>
                </span>
              </span>
              <span className="flex items-center mb-1">
                <IoMapOutline className="mr-1 text-xl" />
                <span>
                  <span className="font-medium mr-1">Dirección: </span>
                  <span className="text-secondary">{event.address}</span>
                </span>
              </span>
              <span className="flex items-center">
                <FiUserCheck className="mr-1 text-xl" />
                {event.min_age
                  ? `Mayores de ${event.min_age} años`
                  : 'Apto para todo público'}
              </span>
            </div>
          </TextTitleCard>
        </div>
      </div>
      <div className="my-10">
        {tickets.length === 0 && (
          <div
            className={`flex justify-center mb-3 ${
              clickedGetTickets ? 'font-medium' : ''
            }`}
          >
            En este momento no hay entradas disponibles
          </div>
        )}
        <div className="flex justify-center">
          <Button
            className="h-12 w-11/12 text-lg"
            disabled={clickedGetTickets || tickets.length === 0}
            primary
            onClick={handleClickBuyTickets}
          >
            Adquirir entradas
          </Button>
        </div>
      </div>
      {importantInfo && (
        <div className="flex justify-center items-center mb-7">
          <div className="w-11/12">
            <TextTitleCard title="Más info">{importantInfo}</TextTitleCard>
          </div>
        </div>
      )}
      <div className="flex flex-col w-full mb-14">
        <h1 className="px-3 mb-2 ml-2">Ubicación</h1>
        <div className="flex justify-center px-5 md:px-6 lg:px-8">
          <iframe
            src={event.maps_link}
            className="w-full mx-auto h-64 sm:h-72 md:h-96 rounded-lg"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default EventPage;
