import React from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { CgClose } from 'react-icons/cg';

interface HamburgerMenuProps {
  onToggle: (open: boolean) => void;
  isOpen: boolean; // New prop
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ onToggle, isOpen }) => {
  const handleToggle = () => {
    onToggle(!isOpen);
  };

  return (
    <button onClick={handleToggle} className="px-2 py-1">
      {isOpen ? <CgClose /> : <RxHamburgerMenu />}
    </button>
  );
};

export default HamburgerMenu;
