import { Fragment, useState } from 'react';
import { PiWarningCircleLight } from 'react-icons/pi';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import SortableTable from './SortableTable';
import MenuDropdown from './MenuDropdown';
import CustomModal from './CustomModal';
import { useDeleteTicketMutation } from '../store';
import Button from './Button';
import IconInfoCard from './IconInfoCard';
import TicketDTO from '../models/ticket';
import CreateTicket from './CreateTicket';

interface Props {
  tickets: TicketDTO[];
  eventId: number;
  businessId: number;
}

const TicketsTable: React.FC<Props> = ({ tickets, eventId, businessId }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);
  const [actionBar, setActionBar] = useState(<div></div>);
  const [deleteTicket, resultsDelete] = useDeleteTicketMutation();

  const handleConfirmDeleteTicket = (ticket: TicketDTO) => {
    deleteTicket({
      businessId: businessId || 0,
      eventId: +eventId,
      ticketId: ticket.id,
    });
    setShowModal(false);
  };

  const handleClose = () => setShowModal(false);

  const handleDeleteTicket = (ticket: TicketDTO) => {
    setModalBody(
      <IconInfoCard
        iconColor="text-red-600"
        icon={PiWarningCircleLight}
        title="¿Deseas eliminar esta entrada?"
        info="Una vez eliminada desaparecerá del listado, pero si ya hubieron ventas de ese tipo de entrada no se eliminarán."
      />,
    );
    setActionBar(
      <div className="w-full">
        <Button
          danger
          onClick={() => handleConfirmDeleteTicket(ticket)}
          className="text-center w-full mt-2"
        >
          Eliminar
        </Button>
      </div>,
    );
    setShowModal(true);
  };

  const handleEditTicket = (ticket: TicketDTO) => {
    setModalBody(
      <Fragment>
        <div className="flex flex-col justify-center items-center mb-5">
          <h1 className="text-xl font-medium mb-4">Editar entrada</h1>
          <p className="text-gray-400 text-sm text-center">
            Podrás realizar las ediciones que necesites en este tipo de entrada
          </p>
        </div>
        <CreateTicket ticket={ticket} onSuccess={handleClose} />
      </Fragment>,
    );
    setShowModal(true);
  };

  const dropDownOptions = [
    {
      label: 'Editar',
      onClick: handleEditTicket,
    },
    {
      label: 'Eliminar',
      onClick: handleDeleteTicket,
    },
  ];

  const config = [
    {
      label: '',
      render: (ticket: TicketDTO) => <div className='flex justify-center items-center'> {ticket.active ? <AiFillCheckCircle className=' text-green-600' /> : <AiFillCloseCircle className='text-red-600' />}</div>,
    },
    {
      label: 'Nombre',
      render: (ticket: TicketDTO) => ticket.name,
      sortValue: (ticket: TicketDTO) => ticket.name,
    },
    {
      label: 'Cantidad',
      render: (ticket: TicketDTO) => `${ticket.amount_taken}/${ticket.total_amount}`,
    },
    {
      label: 'Precio',
      render: (ticket: TicketDTO) => `$ ${ticket.price}`,
    },
    {
      label: '',
      render: (ticket: TicketDTO) => (
        <div>
          <MenuDropdown<TicketDTO>
            options={dropDownOptions}
            data={ticket}
            loading={resultsDelete.isLoading}
          />
        </div>
      ),
    },
  ];

  const keyFn = (ticket: TicketDTO) => `${ticket.id}`;

  return (
    <div>
      <SortableTable data={tickets} config={config} keyFn={keyFn} pagination={false} search={false}/>
      <CustomModal
        actionBar={actionBar}
        onRequestClose={handleClose}
        isOpen={showModal}
      >
        {modalBody}
      </CustomModal>
    </div>
  );
};

export default TicketsTable;
