import {
  NavigateFunction, NavigateOptions, To, useLocation, useNavigate,
} from 'react-router-dom';

const useNavigateWithQuery = (): ((
  path: To,
  options?: NavigateOptions
) => void) => {
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();

  return (path: To, options?: NavigateOptions) => {
    let fullPath;

    if (typeof path === 'string') {
      fullPath = path.includes('?')
        ? `${path}&${location.search.slice(1)}`
        : `${path}${location.search}`;
    } else {
      fullPath = {
        ...path,
        search: path.search
          ? `${path.search}&${location.search.slice(1)}`
          : location.search,
      };
    }

    navigate(fullPath, options);
  };
};

export default useNavigateWithQuery;
