import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import TicketDTO from '../../models/ticket';
import { ticketsApi } from '../apis/tickets';
import { TicketSelection } from '../../components/SelectTicketsTable';

interface TicketsState {
  tickets: TicketDTO[] | null;
  ticketsSelected: TicketSelection[];
}

const initialState: TicketsState = {
  tickets: [],
  ticketsSelected: [],
};

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTickets: (state: TicketsState, action: PayloadAction<TicketDTO[]>): TicketsState => ({ ...state, tickets: action.payload }),
    setTicketsSelected: (state: TicketsState, action: PayloadAction<TicketSelection[]>): TicketsState => ({ ...state, ticketsSelected: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ticketsApi.endpoints.getAllTickets.matchFulfilled,
      (state, action) => ({
        ...state,
        tickets: action.payload || null,
      }),
    );
  },
});

export const { setTickets, setTicketsSelected } = ticketsSlice.actions;
export const ticketsReducer = ticketsSlice.reducer;
