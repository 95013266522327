import { useSelector } from 'react-redux';
import Button from '../components/Button';
import { RootState } from '../store';
import Image from '../components/Image';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

const CreateOrderError: React.FC = () => {
  const navigate = useNavigateWithQuery();
  const event = useSelector((state: RootState) => state.events.selectedEvent);
  if (!event) {
    console.error('no selected event');
  }
  const handleClickTryAgain = () => {
    navigate('../');
  };
  return (
    <div className="h-full">
      <div className="flex flex-col items-center justify-center px-3">
      <div className="flex items-center justify-center mb-3">
          <Image
            src={event?.banner_image || ''}
            height="146px"
            width="100%"
          />
        </div>
        <h1 className="mt-5 text-center">
          Tu solicitud no pudo ser generada de forma correcta
        </h1>
      </div>
      <div className="px-4 mt-5">
        <p>
          Debido a algún inconveniente no pudimos procesar tu solicitud de
          entradas de forma correcta, por lo que te pedimos que intentes
          nuevamente en realizar dicho pedido.
        </p>
      </div>
      <div className="flex flex-col justify-center my-12 px-20">
        <Button primary onClick={handleClickTryAgain} className='h-10'>
          Reintentar
        </Button>
      </div>
      <div className="bg-yellow py-3 mt-8 px-3 mx-4 rounded-lg">
        <p className="text-center">
        Si el problema persiste te pedimos que te comuniques a nuestro <a className='underline' href="https://wa.me/542645515045" target="_blank">WhatsApp</a>.
        </p>
      </div>
    </div>
  );
};
/* <div className="flex justify-center mt-10">
        <Button secondary className="mt-10" onClick={handleClickTryAgain}>
          Reintentar
        </Button>
      </div>
      <div className="flex justify-center mt-3">
        <Button secondary className="mt-10" onClick={handleClickContact}>
          Hablar con operadora
        </Button>
      </div> */

export default CreateOrderError;
