import { useSelector } from 'react-redux';
import { RootState } from '../store';

const useGetDefaultBusinessId = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { selectedBusiness } = useSelector((state: RootState) => state.users);
  if (selectedBusiness) {
    return selectedBusiness.id;
  }
  return user?.user_businesses?.[0]?.business_id || null;
};

export default useGetDefaultBusinessId;
