import { OrderItemData } from '../models/order';
import { formatCurrency } from '../utils/shared';
import TitleTextCard from './TitleTextCard';

interface Props {
  items: OrderItemData[];
  customized: boolean;
  hasOpenPrice?: boolean;
}

const OrderSummary: React.FC<Props> = ({ items, customized, hasOpenPrice }) => (
  <div className="flex justify-center items-center w-full">
    <TitleTextCard title="Resumen">
      {!customized && <span className='font-medium mb-3'>{items[0].name} {items[0].last_name} - {items[0].person_id}</span>}
      {items.map((item, index) => (
        <div className={`${customized ? 'mt-3' : ''} text-secondary text-gray-500`} key={index}>
          <div>
            <span className="font-medium">
              Entrada {index + 1} - {item.ticket.name}
            </span>{' '}
            {!hasOpenPrice && <span>{formatCurrency(item.ticket.price)}</span>}
          </div>
         {customized && <div>
            {item.name} {item.last_name} - {item.person_id}
          </div>}
        </div>
      ))}
    </TitleTextCard>
  </div>
);

export default OrderSummary;
