import { useEffect, useRef, useState } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import { Vector as VectorLayer } from 'ol/layer';
import { ImageStatic, Vector as VectorSource } from 'ol/source';
import Feature, { FeatureLike } from 'ol/Feature';
import Point from 'ol/geom/Point';
import {
  Style,
  Icon,
} from 'ol/style';
import { Select } from 'ol/interaction';
import 'ol/ol.css';
import { Geometry } from 'ol/geom';
import ImageLayer from 'ol/layer/Image';
import { DisplaySeatDTO } from '../models/seat';

interface Props {
  seatsInfo: DisplaySeatDTO[] | null | undefined,
  onSeatsUpdate: (selectedSeats: number[]) => void,
}

const RenderStageMap: React.FC<Props> = ({ seatsInfo, onSeatsUpdate }) => {
  const mapElement = useRef<HTMLDivElement | null>(null);
  const [selectedSeats, setSelectedSeats] = useState<number[]>([]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (mapElement.current) {
      const imageUrl = '/stage-map.png';
      const imageExtent = [-87, -75, 178, 200];
      const selectedIconUrl = '/check-circle.svg';

      const imageLayer = new ImageLayer({
        source: new ImageStatic({
          url: imageUrl,
          imageExtent,
        }),
      });

      const vectorSource = new VectorSource();
      const seatFeatures: Feature<Geometry>[] = [];

      seatsInfo?.forEach((seat) => {
        const seatFeature = new Feature({
          ...seat,
          geometry: new Point([seat.x_coordinate, seat.y_coordinate]),
          id: seat.id,
        });
        seatFeatures.push(seatFeature);
      });

      vectorSource.addFeatures(seatFeatures);

      const vectorLayer = new VectorLayer({
        source: vectorSource,
        declutter: true,
        style: (feature: FeatureLike, resolution: number) => {
          const iconScale = Math.max(0.4, 0.4 / resolution);

          const seatColor = feature.get('sector_color');
          const svg = `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6V0H0V6Z" fill="${seatColor}"/>
          </svg>`;
          const seatId = feature.get('id');
          const isSelected = selectedSeats.includes(seatId);

          const iconUrl = isSelected ? selectedIconUrl : `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;

          return new Style({
            image: new Icon({
              anchor: [0.5, 1],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              src: iconUrl,
              scale: iconScale,
            }),
          });
        },
      });

      const map = new Map({
        target: mapElement.current,
        layers: [imageLayer, vectorLayer],
        view: new View({
          projection: 'EPSG:3857',
          center: [0, 0],
          zoom: 2,
        }),
      });

      const seatExtent = vectorSource.getExtent();
      if (!vectorSource.isEmpty()) {
        map.getView().fit(seatExtent, {
          size: map.getSize(),
          padding: [50, 50, 50, 50],
          maxZoom: 18,
        });
      }

      const selectInteraction = new Select();
      map.addInteraction(selectInteraction);

      selectInteraction.on('select', (e) => {
        e.selected.forEach((feature) => {
          const seatInfo = feature.getProperties();

          const selected = selectedSeats.includes(seatInfo.id);
          let updatedArray;
          if (selected) {
            updatedArray = selectedSeats.filter((seatId) => seatId !== seatInfo.id);
          } else {
            updatedArray = selectedSeats.concat(seatInfo.id);
          }
          setSelectedSeats(updatedArray);
          onSeatsUpdate(updatedArray);
        });
      });

      return () => map.setTarget(undefined);
    }
  }, [seatsInfo, selectedSeats]);

  return <div ref={mapElement} className='map-container' style={{ height: '500px', backgroundColor: '#FFFFFF' }} />;
};

export default RenderStageMap;
