import React, { Fragment, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
}

const Image: React.FC<Props> = ({
  src, height, width, ...rest
}) => {
  const [isImageLoaded, setImageLoaded] = useState(false);

  return (
    <Fragment>
      {isImageLoaded ? null : (
        <Skeleton
          className="rounded-lg opacity-50"
          height={height}
          width={width}
        />
      )}
      <img
        src={src}
        onLoad={() => setImageLoaded(true)} // <-- Set the state when image is loaded
        style={{
          display: isImageLoaded ? 'block' : 'none',
          objectFit: 'cover',
          height: height || 'auto', // <-- Apply height prop
          width: width || '100%', // <-- Apply width prop
        }}
        {...rest}
      />
    </Fragment>
  );
};

export default Image;
