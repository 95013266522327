import React, {
  ChangeEvent,
  InputHTMLAttributes,
  forwardRef,
  useState,
} from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isChecked?: boolean;
  labelClassName?: string;
  onCheckChange?: (isChecked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = forwardRef<
HTMLInputElement,
CheckboxProps
>(({
  id, label, type, isChecked, labelClassName, onCheckChange, ...rest
}, ref) => {
  const [checked, setChecked] = useState(isChecked);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;
    setChecked(newChecked);
    if (onCheckChange) {
      onCheckChange(newChecked);
    }
  };

  return (
    <label className={`flex items-center ${labelClassName}`}>
      <input
        ref={ref}
        type="checkbox"
        className="w-4 h-4 text-purple-600 border-gray-300 rounded"
        checked={checked}
        onChange={handleCheckboxChange}
        {...rest}
      />
      <span className={`checkmark ${label ? 'ml-2' : ''}`}></span>
      {label}
    </label>
  );
});

export default Checkbox;
