import { FaMoneyBill } from 'react-icons/fa';
import { MdAttachMoney } from 'react-icons/md';
import { IoTicketOutline } from 'react-icons/io5';
import { TbPercentage } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GiExpense } from 'react-icons/gi';
import { SellerSummaryDTO } from '../models/order';
import { formatCurrency } from '../utils/shared';
import IconFieldValue from './IconFieldValue';
import TextTitleCard from './TitleTextCard';
import Button from './Button';
import { setSellerDetails } from '../store/slices/orders';

interface Props {
  summary: SellerSummaryDTO;
  customTitle?: string;
  showViewDetails?: boolean;
}

const SellerSummary: React.FC<Props> = ({ summary, customTitle, showViewDetails = true }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewDetails = () => {
    dispatch(setSellerDetails(summary));
    navigate('details');
  };
  return (
    <TextTitleCard title={customTitle ?? summary.seller_name}>
      <IconFieldValue icon={IoTicketOutline} field="Entradas vendidas" value={summary.items_count} />
      <IconFieldValue icon={FaMoneyBill} field="Total vendido" value={formatCurrency(summary.total_amount)} />
      <IconFieldValue icon={TbPercentage} field="Comisión" value={formatCurrency(summary.seller_comission_amount)} />
      <IconFieldValue icon={GiExpense} field="Gastos" value={formatCurrency(summary.transactions_summary.total_amount)} />
      <IconFieldValue icon={MdAttachMoney} field="Saldo" value={formatCurrency(summary.balance)} />
      {showViewDetails && (
        <div className="flex justify-center align-items-center mt-2">
          <Button primary onClick={handleViewDetails}>Ver detalles</Button>
        </div>
      )}
    </TextTitleCard>
  );
};

export default SellerSummary;
