import packageJson from '../../package.json';

const {
  REACT_APP_BASE_BACKEND_URL = 'http://localhost:3000',
  REACT_APP_ENV = 'local',
  REACT_APP_BASE_DOMAIN = 'http://localhost:3001',
} = process.env;

const config = {
  baseURL: REACT_APP_BASE_BACKEND_URL,
  version: packageJson.version,
  env: REACT_APP_ENV,
  baseDomain: REACT_APP_BASE_DOMAIN,
  production: REACT_APP_ENV === 'prod',
};

export default config;
