import React from 'react';

const Footer: React.FC = () => (
  <div className="text-center pt-3 pb-5 bg-white">
    <hr className='mt-1 mb-4 mx-3' />
    <div className='flex items-center justify-center'>
      <img
        src="/logo.png"
        height={28}
        width={28}
        className="inline-block mr-3"
        alt="Avantti logo"
      />
      <div className="text-xl font-light flex items-center">avantti</div>
    </div>
    <div className="text-xs text-gray-500 mt-3 text-secondary font-light">
      ©Copyright @ 2024 avantti.io
      <br />
      All rights reserved.
    </div>
  </div>
);

export default Footer;
