import { SlArrowLeft } from 'react-icons/sl';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

interface Props {
  title: string;
  icon?: React.FC
  onIconClick?: () => void;
}

const Subheader: React.FC<Props> = ({ title, icon, onIconClick }) => {
  const navigate = useNavigateWithQuery();

  return <div className="h-18 w-full flex flex-none justify-between items-center bg-white p-4 text-gray-900">
  <SlArrowLeft onClick={() => navigate('..')} />
  <div className="font-medium flex items-center justify-center">{title}</div>
  {!icon && <div></div>}
  {icon && <div onClick={onIconClick}>{icon(({ className: 'h-5 w-5' }))}</div>}
</div>;
};

export default Subheader;
