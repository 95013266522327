import { useSearchParams } from 'react-router-dom';
import { useGetOrderByCustomIdQuery } from '../store';
import Image from '../components/Image';
import CreateOrderSuccessSkeleton from '../components/CreateOrderSuccessSkeleton';
import TitleTextCard from '../components/TitleTextCard';
import { formatCurrency } from '../utils/shared';

const CreateOrderSuccess: React.FC = () => {
  const [searchParams] = useSearchParams();
  const customId = searchParams.get('id');
  const { data: order, isLoading } = useGetOrderByCustomIdQuery(
    { customId: customId || '' },
    { skip: !customId },
  );

  if (isLoading) {
    return <CreateOrderSuccessSkeleton />;
  }

  if (!order) {
    console.error('no order');
    return <div>Orden no encontrada</div>;
  }

  const unpaid = order.is_manual || order.total_amount === 0;

  return (
    <div className="flex flex-col items-center px-3">
      <div className="w-full max-w-screen-md mx-auto">
        <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center mb-3">
          <Image
            src={order.event.banner_image || ''}
            width="100%"
          />
        </div>
          <h1 className="mt-5 text-center">¡{unpaid ? 'Solicitud' : 'Compra'} de entradas exitosa!</h1>
        </div>
        <div className="px-3 mt-5 text-center">
          <p>
            Número de orden: <strong> #{order.display_id} </strong>
          </p>
        </div>
        <div className="mt-5 text-center">
          <p>
            Tu {unpaid ? 'solicitud' : 'compra'} de tickets para "{order.event.name}" fue procesada con
            éxito.
          </p>
        </div>
        <div className="mt-5">
          <TitleTextCard>
            {order.items.map((item, index) => (
              <div className="mt-3 text-secondary text-gray-500" key={index}>
                <div>
                  <span className="font-medium">
                    Entrada {index + 1} - {item.ticket.name}
                  </span>{' '}
                  <span
                    className={`${
                      order.coupon_id ? 'line-through text-xs' : ''
                    }`}
                  >
                    {formatCurrency(item.ticket.has_open_price ? order.total_amount : item.ticket.price)}
                  </span>
                  {order.coupon_id && (
                    <span className="text-sm text-gray-800">
                      {' '}
                      {formatCurrency(item.ticket.price * (1 - order.coupon.value))}
                    </span>
                  )}
                </div>
                {order.event.require_id_tickets && <div>
                  {item.name} {item.last_name} - {item.person_id}
                </div>}
              </div>
            ))}
          </TitleTextCard>
        </div>
        <div className="bg-yellow py-3 mt-8 px-3 text-center rounded-lg">
          {!order.is_manual && order.total_amount > 0 && <p>
            Estamos procesando tu pago. Una vez que sea aprobado te llegará un
            correo con tus entradas.
          </p>}
          {order.total_amount === 0 && <p>
            Se están procesando los tickets correspondientes. En breve serán enviados a tu correo electrónico.
          </p>}
          {order.is_manual && order.total_amount > 0 && <p>Tu solicitud de entradas fue procesada con éxito. Una vez que la operadora confirme tu pago te llegará un
            correo con tus entradas.</p>}
          <p className='font-medium'>¡Recordá revisar tu casilla de spam!</p>
        </div>
        <p className="px-3 text-sm text-center mt-10 mb-10">
          Si tenés alguna duda podés comunicarte a nuestro{' '}
          <a
            className="underline"
            href="https://wa.me/542645515045"
            target="_blank"
          >
            WhatsApp
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default CreateOrderSuccess;
