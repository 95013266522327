import { useEffect, useState } from 'react';
import EventCardSkeleton from '../components/EventCardSkeleton';
import { useGetAllEventsQuery } from '../store';
import LandingEventCard from '../components/LandingEventCard';
import EventDTO from '../models/event';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

interface Props {
  loadedEvents?: EventDTO[];
}

const AvailableEventsPage: React.FC<Props> = ({ loadedEvents }) => {
  const navigate = useNavigateWithQuery();
  const { data: events, isLoading } = useGetAllEventsQuery({ query: { active: true } }, { skip: !!loadedEvents });
  const [sortedEvents, setSortedEvents] = useState<EventDTO[]>([]);

  useEffect(() => {
    const eventsToShow = loadedEvents || events;
    if (eventsToShow) {
      setSortedEvents([...eventsToShow].filter((e) => e.is_public).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()));
    }
  }, [events, loadedEvents]);

  const handleEventSelect = (eventId: number) => {
    navigate(`/events/${eventId}`);
  };

  return <div className="p-3 bg-gray-50 h-full">
  {isLoading && Array(5).fill('').map((_, i) => < EventCardSkeleton key={i} />)}
  <div className="flex flex-col items-center mb-20">
    {!isLoading && sortedEvents
      && sortedEvents.map((event) => (
        <LandingEventCard
          key={event.id}
          event={event}
          onSelect={handleEventSelect}
        />
      ))}
  </div>
</div>;
};

export default AvailableEventsPage;
