import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  padding?: number;
}

const CardFrame: React.FC<Props> = ({ children, padding = 4 }) => (
  <div className={`flex flex-col items-center flex-grow rounded-lg shadow-sm bg-white mx-4 my-2 p-${padding} w-full`}>
    {children}
    </div>
);
export default CardFrame;
