import { createApi } from '@reduxjs/toolkit/query/react';
import { Response } from '../../models/shared';
import { transformResponseData } from '../utils';
import baseQueryWithReauth from './base';
import { CreateReferralDTO, ReferralDTO, UpdateReferralDTO } from '../../types/Referral';

const referralsApi = createApi({
  reducerPath: 'referralsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['referrals'],
  endpoints: (build) => ({
    getReferralById: build.query<Response<ReferralDTO>, { businessId: number, eventId: number, referralId: number }>({
      query: ({ referralId, businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/referrals/${referralId}`,
        method: 'GET',
      }),
    }),
    getAllReferrals: build.query<(ReferralDTO[] | null), { businessId: number, eventId: number }>({
      providesTags: ['referrals'],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/referrals`,
        method: 'GET',
      }),
      transformResponse: (response: Response<ReferralDTO[]>): (ReferralDTO[] | null) => response.data || null,
    }),
    createReferral: build.mutation<Response<ReferralDTO>, { referral: CreateReferralDTO, businessId: number, eventId: number, }>({
      query: ({
        referral, businessId, eventId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/referrals`,
        method: 'POST',
        body: referral,
      }),
      invalidatesTags: ['referrals'],
    }),
    updateReferral: build.mutation<Response<ReferralDTO>, { referral: UpdateReferralDTO, businessId: number, eventId: number }>({
      query: ({
        referral, businessId, eventId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/referrals`,
        method: 'PUT',
        body: referral,
      }),
      invalidatesTags: ['referrals'],
    }),
    deleteReferral: build.mutation<ReferralDTO | null, { businessId: number, eventId: number, referralId: number }>({
      query: ({
        referralId, businessId, eventId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/referrals/${referralId}`,
        method: 'DELETE',
      }),
      transformResponse: transformResponseData,
      invalidatesTags: ['referrals'],
    }),
  }),
});

export const {
  useGetReferralByIdQuery, useGetAllReferralsQuery, useCreateReferralMutation, useUpdateReferralMutation, useDeleteReferralMutation,
} = referralsApi;
export { referralsApi };
