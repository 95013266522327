import classNames from 'classnames';
import { FC, InputHTMLAttributes, forwardRef } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Tooltip from './Tooltip';

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  errMsg?: string;
  tooltipText?: string;
  labelAbove?: boolean;
  textWhite?: boolean;
  additionalClasses?: string;
}

const FormControlField: FC<CustomInputProps> = forwardRef<
HTMLInputElement,
CustomInputProps
>(({
  id, label, errMsg, type, tooltipText, labelAbove, textWhite, additionalClasses, ...rest
}, ref) => {
  const commonClasses = 'text-black p-3 border focus:outline-none';
  const focusClasses = 'focus:rounded-[0.1875rem] focus:border-gray-400';
  const textInputClasses = classNames(
    commonClasses,
    focusClasses,
    'w-full',
    {
      'border-red-500': errMsg,
      'text-white': textWhite,
      'bg-transparent': !rest.disabled,
      'bg-gray-100': rest.disabled,
    },
    'border-1',
    'border-gray-300',
    'rounded',
    additionalClasses,
  );
  const dateInputClasses = classNames(
    commonClasses,
    focusClasses,
    'w-full',
    'text-left',
    'appearance-none',
    {
      'border-red-500': errMsg,
    },
  );

  return (
    <div className="form-control flex flex-col w-full">
      {labelAbove && (
        <div className="flex items-center w-full">
          <label htmlFor={id} className="text-left">
            {label}
          </label>
          {tooltipText && (
            <Tooltip content={tooltipText}>
              <AiOutlineQuestionCircle className="ml-1" />
            </Tooltip>
          )}
        </div>
      )}
      <div className="flex items-center">
        <input
          ref={ref}
          type={type}
          id={id}
          placeholder={label}
          className={type === 'date' ? dateInputClasses : textInputClasses}
          {...rest}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </div>
      <span className="text-red-500 white-text-shadow">{errMsg}</span>
    </div>
  );
});

export default FormControlField;
