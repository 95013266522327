import { DateTime } from 'luxon';

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const parseFriendlyDate = (isoDate: string, capitalize?: boolean) => {
  const date = DateTime.fromISO(isoDate).setLocale('es');
  const dayOfWeek = date.toFormat('cccc'); // Obtiene el día de la semana con la primera letra en mayúscula
  const { day } = date; // Obtiene el día del mes sin ceros a la izquierda
  const month = date.toFormat('LLLL'); // Obtiene el nombre completo del mes

  return `${capitalize ? capitalizeFirstLetter(dayOfWeek) : dayOfWeek} ${day} de ${month}`;
};

const parseFriendlyDateAndHour = (isoDate: string) => DateTime.fromISO(isoDate).setLocale('es').toFormat('dd/MM - HH:mm');

const formatDateYYYYMMDD = (date: string | Date) => {
  const d = new Date(date);
  const month = `${d.getMonth() + 1}`.padStart(2, '0');
  const day = `${d.getDate()}`.padStart(2, '0');
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

export { parseFriendlyDate, parseFriendlyDateAndHour, formatDateYYYYMMDD };
