/* eslint-disable no-nested-ternary */
import { useParams } from 'react-router-dom';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import { Fragment, useState } from 'react';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import { useGetAllTicketsQuery } from '../store';
import Button from '../components/Button';
import IconInfoCard from '../components/IconInfoCard';
import TicketsTable from '../components/TicketsTable';
import CreateTicket from '../components/CreateTicket';
import CustomModal from '../components/CustomModal';

const AdminTickets: React.FC = () => {
  const { event_id: eventId, business_id: businessId } = useParams();
  const [showModal, setShowModal] = useState(false);

  if (!eventId || !+eventId || !businessId || !+businessId) {
    throw new Error('no event_id or business_id');
  }

  const { data: tickets, isFetching } = useGetAllTicketsQuery({
    businessId: +businessId,
    eventId: +eventId,
  });

  const handleSucessAddTicket = () => {
    setShowModal(false);
  };

  const handleAddTicket = () => {
    setShowModal(true);
  };

  return (
    <div className="px-3 h-screen">
      <h1 className="text-xl font-medium mb-4">Configuración de entradas</h1>

      <div className="card border border-gray-200">
        <div className="flex py-2 justify-between">
          <h1 className="text-lg">Entradas</h1>
          <Button onClick={handleAddTicket} secondary>
            {' '}
            +{' '}
          </Button>
        </div>
        {isFetching && <OrdersTableSkeleton />}{' '}
        {tickets && tickets.length > 0 && (
          <TicketsTable tickets={tickets} eventId={+eventId} businessId={+businessId} />
        )}
        {tickets && tickets.length === 0 && (
          <IconInfoCard
            title="Aún no hay tickets creados"
            info="Aquí aparecerán los diferentes tipos de entradas que crees"
            icon={HiOutlineRocketLaunch}
            iconColor="text-indigo-600"
          />
        )}
      </div>
      <CustomModal
        onRequestClose={() => setShowModal(false)}
        isOpen={showModal}
        actionBar={<div></div>}
      >
        <Fragment>
          <div className="flex flex-col justify-center items-center mb-5">
            <h1 className="text-xl font-medium mb-4">Crear entrada</h1>
            <p className="text-gray-400 text-sm text-center">
              Podrás realizar las ediciones que necesites en este tipo de
              entrada
            </p>
          </div>
          <CreateTicket onSuccess={handleSucessAddTicket} />
        </Fragment>
      </CustomModal>
    </div>
  );
};

export default AdminTickets;
