import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { browserLocalPersistence, onAuthStateChanged, setPersistence } from 'firebase/auth';
import { loadAuth } from '../slices/auth';
import { auth } from '../../config/firebase-config';
import LoadingLottie from '../../components/LoadingLottie';
import useAuthObserver from '../../hooks/useAuthObserver';

const AppInitialization: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const dispatch = useDispatch();
  const [isAuthLoaded, setAuthLoaded] = useState(false);
  const [isPersistenceSet, setPersistenceSet] = useState(false);
  const { isUserDataLoading } = useAuthObserver();

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
        setPersistenceSet(true);
      } catch (error) {
        console.error('Error setting persistence:', error);
        setPersistenceSet(true); // Proceed even if setting persistence fails
      }
    };
    initializeAuth();
  }, []);

  useEffect(() => {
    if (isPersistenceSet) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          dispatch(loadAuth());
        }
        setAuthLoaded(true);
      });

      return () => unsubscribe();
    }
    return () => {};
  }, [dispatch, isPersistenceSet]);

  if (!isAuthLoaded || !isPersistenceSet || isUserDataLoading) {
    return <LoadingLottie />;
  }

  return children;
};

export default AppInitialization;
