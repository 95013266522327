import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const FormSkeleton: React.FC = () => (
  <div className="w-full h-full">
    <div className="mb-7">
      <div className="mb-4">
        <Skeleton height={30} width="50%" />
      </div>
      <div className="mb-4">
        <Skeleton height={21} width="80%" />
      </div>
    </div>
    <div className="mb-7">
      <div className="mb-4">
        <Skeleton height={30} width="50%" />
      </div>
      <div className="mb-4">
        <Skeleton height={21} width="80%" />
      </div>
    </div>
    <div className="mb-7">
      <div className="mb-4">
        <Skeleton height={30} width="50%" />
      </div>
      <div className="mb-4">
        <Skeleton height={21} width="80%" />
      </div>
    </div>
    <div className="flex flex-col justify-center mt-10">
      <Skeleton height={48} width="30%" className="mb-2" />
      <Skeleton height={48} width="30%" />
    </div>
  </div>
);

export default FormSkeleton;
