const getSubdomain = (): string => {
  const { hostname } = window.location;
  const parts = hostname.split('.').filter((part) => part !== 'stg');
  if (parts.length > 2) {
    if (parts[0] !== 'www') { // TODO: revisar a futuro para casos www.<org>.avantti.io
      return parts[0];
    }
  }
  return '';
};

export default getSubdomain;
