import classNames from 'classnames';
import { FC, TextareaHTMLAttributes, forwardRef } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Tooltip from './Tooltip';

interface CustomTextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  id: string;
  label: string;
  errMsg?: string;
  tooltipText?: string;
  labelAbove?: boolean;
  textWhite?: boolean;
}

const FormControlTextArea: FC<CustomTextareaProps> = forwardRef<
HTMLTextAreaElement,
CustomTextareaProps
>(({
  id, label, errMsg, tooltipText, labelAbove, textWhite, ...rest
}, ref) => {
  const commonClasses = 'text-black p-3 border focus:outline-none input-focused';

  const textareaClasses = classNames(
    commonClasses,
    'w-full', // Make the textarea wider
    {
      'border-red-500': errMsg,
      'text-white': textWhite,
      'bg-transparent': !rest.disabled,
      'bg-gray-100': rest.disabled,
    },
    'border-0',
    'border-b-2',
    'rounded',
  );

  return (
    <div className="form-control flex flex-col mb-2 w-full">
      {labelAbove && (
        <div className="flex items-center w-full">
          <label htmlFor={id} className="text-left">
            {label}
          </label>
          {tooltipText && (
            <Tooltip content={tooltipText}>
              <AiOutlineQuestionCircle className="ml-1" />
            </Tooltip>
          )}
        </div>
      )}
      <div className="flex items-center">
        <textarea
          ref={ref}
          id={id}
          placeholder={label}
          className={textareaClasses}
          {...rest}
        />
      </div>
      <span className="text-red-500 white-text-shadow">{errMsg}</span>
    </div>
  );
});

export default FormControlTextArea;
