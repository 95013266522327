import { PiCalendarBlank } from 'react-icons/pi';
import { CiLocationOn } from 'react-icons/ci';
import { GoPerson } from 'react-icons/go';
import { DateTime } from 'luxon';
import EventDTO from '../models/event';
import Button from './Button';

interface EventCardProps {
  event: EventDTO;
  onSelect: (eventId: number) => void;
}

const EventCard: React.FC<EventCardProps> = ({ event, onSelect }) => {
  const ticketsSold = event.tickets.reduce((pv, cv) => pv + cv.amount_taken, 0);
  return (
    <div className='card flex flex-grow p-4 bg-white mb-4 w-full'>
      <div className='h-36 w-36 flex-none'>
        <img
          src={event.banner_image || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMsG_C_QzoMZzPjUrOATtYljG9I3dBybHwjS7yAuDw1w&s'}
          alt={event.name}
          className={`h-full object-cover ${event.active ? '' : 'grayscale'}`}
        />
      </div>
      <div className="ml-3">
        <h2 className="text-lg font-semibold mb-1">{event.name} {event.active ? '' : '(Inactivo)'}</h2>
        <div className="text-xs">
          <p className="text-gray-600 mb-2 flex"><PiCalendarBlank className='mr-1 text-sm'/>{DateTime.fromISO(event.date).setLocale('es').toFormat('EEEE dd/MM')}</p>
          <p className="text-gray-600 mb-2 flex"><CiLocationOn className='mr-1 text-sm'/>{event.place}</p>
          <p className="text-gray-600 mb-2 flex"><GoPerson className='mr-1 text-sm'/> {ticketsSold} tickets vendidos</p>
        </div>
        <Button
          primary
          onClick={() => onSelect(event.id)}
          className='w-44 h-9 flex justify-center items-center'
        >
          Selecccionar
        </Button>
      </div>
    </div>
  );
};

export default EventCard;
