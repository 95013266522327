import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import config from '../../config/config';
import { logout } from '../slices/auth';
import { toastError } from '../../utils/toasts';
import parseErrors from '../../utils/parseErrors';
import getErrorResponse from '../../utils/getErrorResponse';

interface State {
  auth: { token: string; userData: unknown }
}

const baseQuery = fetchBaseQuery({
  baseUrl: `${config.baseURL}/v1/`,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as State).auth;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: string | FetchArgs, api: BaseQueryApi) => {
  const result = await baseQuery(args, api, {});

  if (result.meta?.response?.status === 401) {
    api.dispatch(logout());
    toastError('Su sesión ha expirado');
  } else if (result.error) {
    const errorData = getErrorResponse(result.error);
    toastError(parseErrors(errorData));
  }

  return result;
};

export default baseQueryWithReauth;
