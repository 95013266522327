// Counter.tsx
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

interface Props {
  onValueChange?: (newValue: number) => void;
  upperLimit?: number;
  initialValue?: number;
}

const Counter: React.FC<Props> = ({
  onValueChange, upperLimit, initialValue = 0,
}) => {
  const [count, setCount] = useState(initialValue);

  useEffect(() => {
    setCount(initialValue);
  }, [initialValue]);

  const handleUpdateCount = (addValue: number) => {
    toast.dismiss();
    if ((upperLimit && (count + addValue > upperLimit))) {
      toast('Cantidad máxima alcanzada', {
        icon: 'ℹ️',
        position: 'bottom-center',
        className: 'mb-28',
      });
      return;
    }
    if (count + addValue < 0) {
      toast('Cantidad mínima alcanzada', {
        icon: 'ℹ️',
        position: 'bottom-center',
        className: 'mb-28',
      });
      return;
    }

    setCount((value) => value + addValue);
  };

  useEffect(() => {
    if (onValueChange) {
      onValueChange(count);
    }
  }, [count]);

  return (
    <div className="flex items-center border-none rounded">
      <button
        onClick={() => handleUpdateCount(-1)}
        className="px-3 py-1 hover:bg-gray-300 focus:outline-none"
      >
        –
      </button>
      <span className="px-3 py-1">{count}</span>
      <button
        onClick={() => handleUpdateCount(1)}
        className="px-3 py-1 hover:bg-gray-300 focus:outline-none"
      >
        +
      </button>
    </div>
  );
};

export default Counter;
