import { useNavigate } from 'react-router-dom';
import InvitationDTO from '../models/invitation';
import { AuthUserData } from '../models/user';
import { useAcceptInvitationMutation } from '../store';
import { toastError, toastSucces } from '../utils/toasts';
import Button from './Button';
import TextTitleCard from './TitleTextCard';

interface Props {
  user: AuthUserData;
  invitation: InvitationDTO;
}

const JoinBusinessCard: React.FC<Props> = ({ user, invitation }) => {
  const navigate = useNavigate();
  const [acceptInvitation, acceptResults] = useAcceptInvitationMutation();

  const handleAccept = async () => {
    try {
      await acceptInvitation({ businessId: invitation.business_id, userId: user.id, token: invitation.token });
      toastSucces('Invitación aceptada con éxito.');
      navigate(`/admin/${invitation.business_id}/events`);
    } catch (e) {
      console.error(e);
      toastError('Error al aceptar invitación.');
    }
  };

  // const handleReject = () => {
  //   console.log('Petition rejected');
  // };

  return (
    <TextTitleCard
      className="w-full max-w-md mt-5"
      title={`Invitación para unirte a ${invitation.business.name}`}
    >
      <div className="flex flex-col align-items-center justify-center mt-3">
        <Button loading={acceptResults.isLoading} className="mb-2 h-12" primary onClick={handleAccept}>
          Aceptar
        </Button>
        {/* <Button loading={acceptResults.isLoading} transparent onClick={handleReject}>
          Rechazar
        </Button> */}
      </div>
    </TextTitleCard>
  );
};

export default JoinBusinessCard;
