import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { businessesApi } from '../apis/business';
import { BusinessDTO } from '../../models/business';

interface BusinessState {
  business: BusinessDTO | null;
}

const initialState: BusinessState = {
  business: null,
};

const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusiness: (state, action: PayloadAction<BusinessDTO>) => ({ ...state, business: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      businessesApi.endpoints.getBusinessById.matchFulfilled,
      (state, action) => {
        if (!action.payload) {
          throw new Error('no business data for payload');
        }
        return {
          ...state,
          business: action.payload,
        };
      },
    );
  },
});

export const { setBusiness } = businessSlice.actions;
export const businessReducer = businessSlice.reducer;
