import React, { useState, useEffect } from 'react';
import { OnResultFunction, QrReader } from 'react-qr-reader';
import { useParams } from 'react-router-dom';
import { IoSearchOutline } from 'react-icons/io5';
import {
  useDecodeQrCodeQuery,
  useUpdateUsedOrderItemMutation,
} from '../store';
import { DecodedData } from '../store/apis/orders';
import Subheader from './Subheader';
import QRResponse from './QrResponse';
import SearchByPersonId from './SearchPersonId';

const QRCodeScanner: React.FC = () => {
  const [qrData, setQrData] = useState<DecodedData | null>(null);
  const [text, setText] = useState<string | null>(null);
  const [showResponse, setShowResponse] = useState(false);
  const [showConfirmedResponse, setShowConfirmedResponse] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const [updateUsedOrder] = useUpdateUsedOrderItemMutation();
  const [qrResponse, setQrResponse] = useState(<div></div>);
  const { event_id: eventId, business_id: businessId } = useParams();
  const [personId, setPersonId] = useState('');
  const [searchByPersonId, setSearchByPersonId] = useState(false);
  const [isScanning, setIsScanning] = useState(true);

  if (!eventId || !businessId) {
    throw new Error('no eventId or businessId');
  }

  const {
    data,
    error,
    isFetching: isDecoding,
  } = useDecodeQrCodeQuery(
    {
      data: text,
      eventId: +eventId,
      businessId: +businessId,
      personId,
    },
    { skip: !text && !personId },
  );

  const cleanData = () => {
    setShowResponse(false);
    setQrResponse(<div>error: recargar</div>);
    setText(null);
    setQrData(null);
    setPersonId('');
  };

  const handleClose = () => {
    cleanData();
  };

  const handleAcceptModal = () => {
    if (qrData && !qrData.used_at) {
      updateUsedOrder({
        businessId: +businessId,
        eventId: qrData.event_id,
        orderId: qrData.order_id,
        itemId: qrData.item_id,
      });
      setAnimationClass('fade-in');
      setShowConfirmedResponse(true);

      setTimeout(() => {
        setAnimationClass('fade-out');
        setTimeout(() => {
          setShowConfirmedResponse(false);
        }, 1000);
        cleanData();
      }, 1000);
    }
  };

  useEffect(() => {
    if (!text && !personId) {
      return;
    }
    setShowResponse(true);
    if (data) {
      setQrData(data);
    }
    if (error) {
      setQrData(null);
    }
  }, [data, text, personId]);

  useEffect(() => {
    if (qrData && qrData.valid && !qrData.used_at) {
      setQrResponse(
        <QRResponse
          type="success_unconfirmed"
          qrData={qrData}
          onRetry={handleClose}
          onConfirm={handleAcceptModal}
        />,
      );
    } else if (qrData && qrData.used_at) {
      setQrResponse(
        <QRResponse type="used" qrData={qrData} onRetry={handleClose} />,
      );
    }
  }, [qrData]);

  useEffect(() => {
    if (error && 'status' in error && error.status !== 404) {
      setQrResponse(
        <QRResponse type="error" qrData={qrData} onRetry={handleClose} />,
      );
    } else if (error && 'status' in error && error.status === 404) {
      setQrResponse(
        <QRResponse type="error" qrData={qrData} onRetry={handleClose} />,
      );
    }
  }, [error]);

  const handleResult: OnResultFunction = (result) => {
    if (result && isScanning) {
      setText(result?.getText());
      setIsScanning(false);
      setTimeout(() => setIsScanning(true), 2000); // Add a 2-second delay
    }
  };

  const handleIconClick = () => {
    setSearchByPersonId(true);
  };

  const handleValidatePersonId = (personIdInput: string) => {
    setSearchByPersonId(false);
    setPersonId(personIdInput);
  };

  return (
    <div className="h-screen bg-gray-50">
      <Subheader
        title="Escáner QR"
        icon={IoSearchOutline}
        onIconClick={handleIconClick}
      />
      {showConfirmedResponse && (
        <div className={`${animationClass} h-full`}>
          <QRResponse
            type="success_confirmed"
            qrData={qrData}
            onRetry={handleClose}
          />
        </div>
      )}
      {searchByPersonId && (
        <SearchByPersonId onValidate={handleValidatePersonId} />
      )}
      <div hidden={showResponse || showConfirmedResponse || searchByPersonId}>
        <div className="relative h-screen w-screen">
          <div hidden={showResponse} className="absolute inset-0">
            <QrReader
              onResult={handleResult}
              constraints={{ facingMode: 'environment' }}
              containerStyle={{ width: '100vw', height: '100vh' }}
              videoContainerStyle={{
                width: '100vw',
                height: '100vh',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              videoStyle={{
                width: '100vw',
                height: '100vh',
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          </div>
          {showResponse && qrResponse}
        </div>
      </div>
      {showResponse && !isDecoding && (
        <div className="h-screen w-full flex items-center justify-center">
          {qrResponse}
        </div>
      )}
      {showResponse && isDecoding && (
        <div className="h-screen w-full flex items-center justify-center">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default QRCodeScanner;
