// Tooltip.tsx
import React, { useState, useRef, useEffect } from 'react';

interface TooltipProps {
  content: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  const [visible, setVisible] = useState(false);
  const [isLeft, setIsLeft] = useState(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleToggle = () => {
    if (tooltipRef.current) {
      const rect = tooltipRef.current.getBoundingClientRect();
      setIsLeft(rect.left + (rect.width / 2) < window.innerWidth / 2);
    }
    setVisible(!visible);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={tooltipRef} className="relative inline-block cursor-pointer" onClick={handleToggle}>
      {children}
      {visible && (
        <div
          className={`absolute bottom-full mb-2 ${isLeft ? 'left-0' : 'right-0'} transform px-2 py-1 bg-gray-900 text-white text-sm rounded-lg w-48 max-h-20 overflow-y-auto opacity-75`}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
