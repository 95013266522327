import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { RootState } from '../store';
import Image from './Image';

const CreateOrderOutlet: React.FC = () => {
  const event = useSelector((state: RootState) => state.events.selectedEvent);
  const location = useLocation();
  const isBackoffice = location.pathname.includes('admin');
  const showPoll = event?.has_polls && !isBackoffice;

  // mejores practicas de la vida, no se hardcodeo nada
  const currentStep = () => {
    if (location.pathname.includes('orders/new/tickets')) {
      return 1;
    }
    if (location.pathname.includes('orders/new/entry-data')) {
      return 2;
    }
    if (location.pathname.includes('orders/new/poll')) {
      return 3;
    }
    if (location.pathname.includes('orders/new/checkout')) {
      return showPoll ? 4 : 3;
    }
    return 0;
  };

  const getStepsNumber = () => (showPoll ? 4 : 3);

  return (
    <div className="pb-3">
      {isBackoffice && (
        <h1 className="text-2xl mb-3 ml-2">Carga de entradas</h1>
      )}
      {!isBackoffice && (
        <div className="flex items-center justify-center mb-3">
          <Image
            src={event?.banner_image || ''}
            height="146px"
            width="100%"
          />
        </div>
      )}
      <div className="flex flex-col justify-center items-center mb-5">
        <strong>
          <h2 className="text-xl">{event?.name}</h2>
        </strong>
        {!isBackoffice && (
          <span className="text-sm">Organiza: {event?.business?.name}</span>
        )}
      </div>
      <div className="px-3">
        <div className="flex align-items-center justify-center bg-white shadow rounded-lg py-1 mb-4">
          <h3>Paso {currentStep()} de {getStepsNumber()}</h3>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default CreateOrderOutlet;
