import React, { ChangeEvent, FC } from 'react';

interface SearchInputProps {
  value: string;
  onChange: (newVal: string) => void;
}

const SearchInput: FC<SearchInputProps> = ({ value, onChange }) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="flex items-center p-1 bg-white shadow-md transition duration-300 ease-in-out hover:shadow-lg">
      <span className="text-gray-500 text-sm">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l5.272 5.272a1 1 0 01-1.414 1.414l-5.272-5.272A6 6 0 012 8z" />
        </svg>
      </span>
      <input
        className="ml-1 p-1 text-sm flex-grow rounded-full outline-none transition duration-300 ease-in-out focus:bg-gray-100"
        value={value}
        onChange={handleInputChange}
        placeholder="Buscar..."
      />
    </div>
  );
};

export default SearchInput;
