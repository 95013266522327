import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';
import Button from './Button';
import config from '../config/config';
import { RootState, logout } from '../store';
import { auth } from '../config/firebase-config';
import useGetDefaultBusinessId from '../hooks/useGetFirstBusinessId';
import getSubdomain from '../utils/getSubdomain';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { token, user } = useSelector((state: RootState) => state.auth);
  const businessId = useGetDefaultBusinessId();
  const dispatch = useDispatch();

  const handleMenuToggle = (open: boolean) => {
    setIsMenuOpen(open);
  };

  const handleClick = (path: string) => {
    if (getSubdomain()) {
      window.location.href = `${config.baseDomain}${path}`;
    } else {
      navigate(path);
    }
    setIsMenuOpen(false);
  };

  const handleLogout = (path: string) => {
    handleClick(path);
    signOut(auth)
      .then(() => {
        dispatch(logout());
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const handleClickSellAvantti = () => {
    window.open('https://organizadores.avantti.io/', '_blank');
  };

  const handleClickHelp = () => {
    window.open('https://wa.me/message/GV2TPBX7VGT5A1', '_blank');
  };

  return (
    <>
      <header className="h-18 w-full flex flex-none justify-between items-center bg-white p-4 text-gray-900">
        <span
          onClick={() => {
            window.location.href = config.baseDomain;
          }}
          className="flex h-18 cursor-pointer"
        >
          <img src="/logo.png" height={20} width={32} className="mr-3" />
          <div className="text-2xl">avantti</div>
        </span>
        <HamburgerMenu isOpen={isMenuOpen} onToggle={handleMenuToggle} />
      </header>
      {isMenuOpen && (
        <div
          ref={menuRef}
          style={{ top: '3.9rem' }}
          className={`bg-white text-gray-900 absolute z-50 w-full md:w-1/2 transition-opacity duration-300 ease-in-out 
          ${isMenuOpen ? 'opacity-100' : 'opacity-0'} top-18`}
        >
          <nav className="p-4">
            <ul className="flex flex-col justify-center items-center space-y-2">
              <li className="h-12 cursor-pointer">
                <a onClick={() => handleClick('/')}>Home</a>
              </li>
              <li className="h-12 cursor-pointer">
                <a onClick={handleClickSellAvantti}>
                  Quiero vender con avantti
                </a>
              </li>
              <li className="h-12 cursor-pointer">
                <a onClick={handleClickHelp}>Ayuda</a>
              </li>
              {token && businessId && (
                <li className="h-12 cursor-pointer">
                  <a onClick={() => handleClick(`/admin/${businessId}/events`)}>
                    Mis eventos
                  </a>
                </li>
              )}
              {token && (user?.user_businesses.length > 1 || user?.type === 'ROOT') && (
                <li className="h-12 cursor-pointer">
                  <a onClick={() => handleClick('/admin')}>Mis organizaciones</a>
                </li>
              )}
              {token && (
                <li className="h-12 cursor-pointer">
                  <a onClick={() => handleClick('/admin/invitations')}>
                    Mis invitaciones
                  </a>
                </li>
              )}
              {!token && (
                <li>
                  <Button primary>
                    <a onClick={() => handleClick('/login')}>Iniciar sesión</a>
                  </Button>
                </li>
              )}
              {token && (
                <li>
                  <Button secondary>
                    <a onClick={() => handleLogout('/')}>Cerrar sesión</a>
                  </Button>
                </li>
              )}
            </ul>
            <div className="text-xs">v{config.version}</div>
          </nav>
        </div>
      )}
    </>
  );
};

export default Header;
