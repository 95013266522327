import React, { useEffect, useState } from 'react';
import { useGetBusinessBySubdomainQuery } from '../store';
import EventDTO from '../models/event';
import AvailableEventsPage from './AvailableEventsPage';
import EventPage from './EventPage';
import LoadingLottie from '../components/LoadingLottie';

interface Props {
  subdomain: string;
}

const BusinessLanding: React.FC<Props> = ({ subdomain }) => {
  const { data: business, isLoading } = useGetBusinessBySubdomainQuery(subdomain);
  const [activeEvents, setActiveEvents] = useState<EventDTO[]>([]);

  useEffect(() => {
    if (business) {
      setActiveEvents(business.events.filter((e) => e.active));
    }
  }, [business]);

  if (isLoading) {
    return <LoadingLottie />;
  }

  if (!business && !isLoading) {
    return <div>Organizador no encontrado</div>;
  }

  return (
    <div>
      {activeEvents.length > 1 && <AvailableEventsPage loadedEvents={activeEvents} />}
      {activeEvents.length === 1 && <EventPage loadedEventId={activeEvents[0].id} />}
      {activeEvents.length === 0 && <div>Este organizador aún no tiene eventos publicados</div>}
    </div>
  );
};

export default BusinessLanding;
