import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
import OrdersTableSkeleton from './OrdersTableSkeleton';

const EventPageSkeleton = () => (
  <div className="flex-grow">
    <div className="event-colors">
      <div className="flex flex-col w-full px-3 mb-3">
        <Skeleton height={150} />
      </div>
      <div className="w-full py-3 mb-3">
        <Skeleton height={21} count={3} />
      </div>
      <div className="flex flex-col px-3 text-lg mb-3">
        <Skeleton height={30} count={3} />
      </div>
      <div className="flex flex-col mb-3 px-3">
        <h1>Tickets</h1>
        <OrdersTableSkeleton />
      </div>
      <div className="flex flex-col mb-3 px-3">
        <h1>Más info</h1>
        <Skeleton height={21} count={4} />
      </div>
      <div className="flex flex-col w-full px-3">
        <h1>Ubicación</h1>
        <Skeleton height={200} />
      </div>
    </div>
  </div>
);

export default EventPageSkeleton;
